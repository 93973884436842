import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import { request } from '../../util/fetchAPI';
import { Spin } from 'antd';

export default function ChangePwd() {
  const [state, setState] = useState({ password: "", newPwd: "", confirmPwd: "" })
  const [isError, setIsError] = useState(false)
  const [submitButton, setSubmitButton] = useState(true);
  const { user } = useSelector((state) => state.auth);

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const passwordHandler = async (e) => {
    try {
      e.preventDefault()
      if (state.confirmPwd !== state.newPwd) {
        return setIsError(true)
      }
      setIsError(false)
      setSubmitButton(false)
      const data = await request(`/user/changePasword/${user._id}`, "POST", {
        "Content-Type": "application/json"
      }, { ...state })
      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setState({ password: "", newPwd: "", confirmPwd: "" })
      } else {
        errorNotifier(data.data.message)
      }
    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  const ShowPassword = () => {
    var x = document.getElementById("myInput");
    var y = document.getElementById("myInput2");
    if (x.type === "password" && y.type === "password") {
      x.type = "text";
      y.type = "text";
    } else {
      x.type = "password";
      y.type = "password";
    }
  };


  return (
    <div className='flex flex-col justify-center items-center w-[100%]'>
      <ToastContainer />
      <h1>Format Setting</h1>
      <div className='flex justify-center w-[80%]'>
        <form onSubmit={passwordHandler}>
          <div className='inputBox'>
            <div>
              <label>Old Password</label>
              <input
                className='form-control p-2'
                type='password'
                name='password'
                value={state?.password}
                onChange={stateHandler} required
              />
            </div>

            <div>
              <label>New Password</label>
              <input
                id="myInput"
                className='form-control p-2'
                type='password'
                name='newPwd'
                value={state?.newPwd}
                onChange={stateHandler} required
              />
            </div>
          </div>

          <div className='inputBox'>
            <div>
              <label>Confirm Password</label>
              <input
                id="myInput2"
                className='form-control p-2'
                type='password'
                name='confirmPwd'
                value={state?.confirmPwd}
                onChange={stateHandler} required
              />
              <div className={isError ? "text-red-600 font-semibold" : "hidden"}>
                Confirmed Password Not Macthed
              </div>
            </div>
          </div>

          <div className='pt-2'>
            Show Password
            <input
              type="checkbox"
              className="form-check-input ml-1 "
              id="gridCheck"
              onClick={ShowPassword}
            />
          </div>
          <div>
            <button className='SubButton' disabled={!submitButton}>
              {submitButton ? 'Submit' : (
                <>
                  Please wait
                  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />} />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
