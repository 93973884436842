import React from 'react'
import { useNavigate } from "react-router-dom"


export default function InvoiceRateHis({invoiceRateHistory}) {
  console.log(invoiceRateHistory)
  const navigate = useNavigate()
  return (

    <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-4 py-3">#</th>
                <th scope="col" className="px-4 py-3">Invoices No</th>
                <th scope="col" className="px-4 py-3">Rate</th>
                <th scope="col" className="px-4 py-3">Last Qty</th>
                <th scope="col" className="px-4 py-3">Ref/PO</th>
                <th scope="col" className="px-4 py-3">Payment Term</th>
                <th scope="col" className="px-4 py-3">Dated</th>
                <th scope="col" className="px-4 py-3">Status</th>
              </tr>
              </thead>
              <tbody>
          {invoiceRateHistory.map((data, index)=>(
            <tr key={data._id} className="border-b dark:border-gray-700 cursor-pointer"
             onClick={() => navigate(`/InvoiceView/${data.InvoiceId
              ?._id}`)}>
            <td className="px-4 py-3">{++index}</td>
            <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.InvoiceId?.InvoiceNo}</th>
            <td className="px-4 py-3">{data.rate}.00</td>
            <td className="px-4 py-3">{data.qty}</td>
            <td className="px-4 py-3">{data.InvoiceId?.refPO}</td>
            <td className="px-4 py-3">{data.InvoiceId?.status}</td>
            <td className="px-4 py-3">{data.InvoiceId?.InvoiceDate}</td>
            <td className="px-4 py-3">
                      {data.isFinal
                        ?
                        <span className='text-lime-600 font-semibold'>Final</span>
                        :
                        <span className='text-red-600 font-semibold'>Pending</span>
                      }
                    </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
  )
}
