import React from 'react'
import UserManagerForm from './UserManagerForm'
import UserManagerList from './UserManagerList'

export default function UserManager() {
  return (
    <div>
        <div>
            <UserManagerForm/>
        </div>
        <div>
            <UserManagerList/>
        </div>
    </div>
  )
}
