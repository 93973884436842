/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast,Bounce  } from 'react-toastify';
import "./QuotationView.css"
import { useParams } from 'react-router-dom';
import { request } from '../../util/fetchAPI';

export default function QuotationView() {

  const [quoteData, setQuoteData] = useState()
  const [productData, setProductsData] = useState([])
  const [termCond, setTermCond] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null);
  const {id} = useParams()

  const successNotifier = (data)=>{
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
  }

  const errorNotifier = (data)=>{
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
  }

  const handleRowClick = (id) => {
    setSelectedRowId(selectedRowId === id ? null : id);
  };

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const calculateGrandTotal = () => {
    return productData.reduce((acc, data) => {
      const taxRate = data.tax || 0; // Default to 0 if tax is not provided
      const totalForItem = (data.rate * (1 + taxRate / 100)) * data.qty;
      return acc + totalForItem;
    }, 0);
  };

  const fetchQuoteData =async (id)=>{
    try {

      const data = await request(`/quote/getQuoteBy/${id}`,"GET")
      setQuoteData(data.QuoteData)
      setProductsData(data.QuoteProduct)
      setTermCond(data?.QuoteData?.termCond)

      if (data.status === 200) {
        successNotifier("Client Has Fetched")
      } else {
        errorNotifier(data.data?.message)
      }

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }


  useEffect(()=>{
    fetchQuoteData(id)
  },[id])


  return (
    <div className='QuoteContainer'>
  <ToastContainer />
      <h1>View Quotation</h1>
      <div className='formContiner'>
        <form >
        <div className='inputBox'>
          <div>
          <label htmlFor="">Quotation No.#</label>
          <input type="text" value={quoteData?.QuoteNo} />
          </div>
          <div>
          <label htmlFor="">Date</label>
          <input type="date" value={quoteData?.QuoteDate} readOnly/>
          </div>

          </div>

          <div className='inputBox'>
          <div>

            <label htmlFor="">Client Id</label>
            <input type="text" value={quoteData?.ClientId?.ClientId} readOnly/>
            </div>

              <div>
              <label htmlFor="">Client Name</label>
              <input type="text" value={quoteData?.ClientId?.ClientName} readOnly/>
              </div>

          </div>

          <div className='inputBox'>
            <div>
            <label htmlFor="">Subject</label>
            <textarea type="text" className='p-2' value={quoteData?.Subject} readOnly/>
            </div>

            <div>
            <label htmlFor="">Current Status</label>
            <input type="text" className='p-2' value={quoteData?.isFinal ? "Final - Invoice Has Been Genrated" : "Pending - Invoice Not Genrated"} readOnly/>
            </div>

          </div>


          <div className="overflow-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
            <th scope="col" className="px-4 py-3 w-10">Sr.</th>
            <th scope="col" className="px-4 py-3 w-44">Product Code</th>
            <th scope="col" className="px-4 py-3 w-96">Product Name</th>
            <th scope="col" className="px-4 py-3 w-52">Specs</th>
            <th scope="col" className="px-4 py-3 w-16">Unit</th>
            <th scope="col" className="px-4 py-3 w-16">Qty</th>
            <th scope="col" className="px-4 py-3 w-28">Rate</th>
            <th scope="col" className="px-4 py-3 w-28">Tax%</th>
            <th scope="col" className="px-4 py-3 w-28">Tax</th>
            <th scope="col" className="px-4 py-3 w-32">Amount</th>
          </tr>
          </thead>

          <tbody>
          {productData.map((data, index)=>(
             <tr key={index} className={selectedRowId === index ? "bg-indigo-800 text-white" : "text-black"} onClick={() => handleRowClick(index)}>
           <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1 }</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{data.ProductId?.ProductCode}</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{data.ProductId?.ProductName}</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{data.Specs}</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{data.ProductId?.ProductUnit}</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{data.qty}</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{formatNumberToInternational(data.rate)}.00</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{formatNumberToInternational(data.tax)}%</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">{formatNumberToInternational(data.rate * (data.tax/100))}.00</td>
            <td className="px-2 py-2 border-2 border-gray-400 w-10">
            {formatNumberToInternational(((data.rate * (data.tax/100)) + data.rate)* data.qty)}.00</td>
          </tr>
          ))

          }
          </tbody>

        </table>
        </div>

            <div className="flex justify-end mt-4">
              <h3 className="text-xl font-semibold">
                 Grand Total: {formatNumberToInternational(calculateGrandTotal())}.00
              </h3>
            </div>

        <div className='mt-10'>
        <h1 className="flex text-2xl gap-2 cursor-pointer">Terms & Condition</h1>
        <ul style={{listStyle:"none"}}>
         {termCond && termCond.map((data, index)=>(
          <li>{++index} - {data.description}</li>))}
        </ul>

      </div>


        </form>
      </div>

    </div>
  )
}


