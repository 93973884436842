import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { MdDelete } from "react-icons/md";
import { LoadingOutlined } from '@ant-design/icons';
import { request } from '../../util/fetchAPI';
import { IoCloudDone } from "react-icons/io5";
import { Spin } from 'antd';

export default function FormateSetting() {
  const [headerPhoto, setHeaderPhoto] = useState(null);
  const [footerPhoto, setFooterPhoto] = useState(null);
  const [stampPhoto, setStampPhoto] = useState(null);
  const [selectedRow, setSelectedRow] = useState('')
  const [selectAccount, setSelectAccount] = useState('')
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [Data, setData] = useState([])
  const [accName, setaccName] = useState(null);
  const [submitButton, setSubmitButton] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)



  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showPreview = () => {
    setIsPreviewOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsPreviewOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsPreviewOpen(false);
  };

  const stateHandler = (e, type) => {
    const file = e.target.files[0];
    if (type === 'header') {
      setHeaderPhoto(file);
    } else if (type === 'footer') {
      setFooterPhoto(file);
    } else if (type === 'stamp') {
      setStampPhoto(file);
    }
  };

  const uploader = async (e) => {
    e.preventDefault();

    // Disable submit button while uploading
    setSubmitButton(false);

    try {
      if (!headerPhoto || !footerPhoto || !stampPhoto) {
        setSubmitButton(true);
        return errorNotifier("Please upload all images.");
      }

      // Generate unique filenames for each file
      let headerFilename = headerPhoto.name
      let footerFilename = footerPhoto.name
      let stampFilename = stampPhoto.name

      // Create a FormData object and append files
      const formData = new FormData();
      formData.append('Header', headerPhoto);  // Field name 'headerFile'
      formData.append('headerFilename', headerFilename);  // Field name 'headerFilename'

      formData.append('Footer', footerPhoto);  // Field name 'footerFile'
      formData.append('footerFilename', footerFilename);  // Field name 'footerFilename'

      formData.append('Stamp', stampPhoto);  // Field name 'stampFile'
      formData.append('stampFilename', stampFilename);  // Field name 'stampFilename'

      // Additional fields that need to be sent along with the FormData
      formData.append('accName', accName);
      formData.append('createdBy', user._id);

      // Send the form data to the server
      const uploadResponse = await fetch('https://api.quotemanager.online/upload/image', {
        method: 'POST',
        body: formData,  // Send the entire form data with files and other fields
      });

      const Responce = await uploadResponse.json()
      if (uploadResponse.status === 200) {
        fetchFormate()
        return successNotifier(Responce.message);
      } else {
        return errorNotifier(Responce.message);
      }

    } catch (error) {
      errorNotifier('Failed to upload images');
    } finally {
      setSubmitButton(true);  // Re-enable the submit button
    }
  };


  const fetchFormate = async ()=>{
    try {

      const data = await request(`/settings/getAllPictures?createdBy=${user._id}`, "GET")
      setData(data)
      if (data.status !== 200) {
        errorNotifier(data.data?.message)
      }

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  const deleteHandler = async (id)=>{
    try {

      const data = await request(`/settings/deletPictures/${id}`, "DELETE")
      handleOk()
      fetchFormate()
    if(data.status === 200){
        successNotifier(data.data.message)
      }else{
        errorNotifier(data.data.message)
      }
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(()=>{
    fetchFormate()
  },[])



  return (
    <div className='flex flex-col justify-center items-center w-[100%]'>
      <ToastContainer />
      <h1>Format Setting</h1>
      <div className='flex justify-center w-[80%]'>
        <form onSubmit={uploader}>
          <div className='inputBox'>
            <div>
              <label htmlFor='header'>Header</label>
              <input
                className='form-control p-2'
                type='file'
                id='header'
                onChange={(e) => stateHandler(e, 'header')}

              />
            </div>

            <div>
              <label htmlFor='footer'>Footer</label>
              <input
                className='form-control p-2'
                type='file'
                id='footer'
                onChange={(e) => stateHandler(e, 'footer')}

              />
            </div>
          </div>

          <div className='inputBox'>
            <div>
              <label htmlFor='stamp'>Sign Stamp</label>
              <input
                className='form-control p-2'
                type='file'
                id='stamp'
                onChange={(e) => stateHandler(e, 'stamp')}

              />
            </div>

            <div>
              <label htmlFor="">Select Account</label>
              <select name="accountName" id=""
              onChange={(e)=>setaccName(e.target.value)}>
                <option hidden>Chose...</option>
                <option value="Quote">Quote</option>
                <option value="Invoice">Invoice</option>
              </select>
            </div>
          </div>

          <div>
            <button className='SubButton' disabled={!submitButton}>
              {submitButton ? 'Submit' : (
                <>
                  Please wait
                  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />} />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
      <table className=" w-3/4 text-sm text-left text-gray-500 dark:text-gray-400 mt-5 mb-20">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                 <th scope="col" className="px-4 py-3">#</th>
                 <th scope="col" className="px-4 py-3">Header</th>
                 <th scope="col" className="px-4 py-3">Footer</th>
                 <th scope="col" className="px-4 py-3">Stamp</th>
                 <th scope="col" className="px-4 py-3">Account Name</th>
                 <th scope="col" className="px-4 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
            {Data && Data.map((data, index)=>(
              <tr key={data._id} className="border-b dark:border-gray-700">
              <td className="px-4 py-3">{++index}</td>
              <td className="px-4 py-3" onClick={()=>{setSelectAccount(data.Header);showPreview() }}>{data.Header  ? <IoCloudDone className='text-2xl text-blue-500 cursor-pointer'/> : "N/A"}</td>
              <td className="px-4 py-3" onClick={()=>{setSelectAccount(data.Footer);showPreview() }}>{data.Footer  ? <IoCloudDone className='text-2xl text-blue-500 cursor-pointer'/> : "N/A"}</td>
              <td className="px-4 py-3" onClick={()=>{setSelectAccount(data.Stamp);showPreview() }}>{data.Stamp   ? <IoCloudDone className='text-2xl text-blue-500 cursor-pointer'/> : "N/A"}</td>
              <td className="px-4 py-3">{data.accName}</td>
              <td className="px-4 py-3">
                <MdDelete className='text-xl cursor-pointer' onClick={()=>{showModal();
                  setSelectedRow(data._id)}} /></td>
            </tr>
            ))
              }
            </tbody>
        </table>

        <Modal title="Quotation Preview" open={isPreviewOpen} onOk={handleOk} onCancel={handleCancel}>
          <img src={`https://api.quotemanager.online/images/${selectAccount}`} alt="" />
      </Modal>

        <Modal title="Deletion Waring" open={isModalOpen} onOk={() => {
        deleteHandler(selectedRow)
      }} onCancel={handleCancel}>
        <p className='text-red-600 font-semibold'>Would you like to delete this data?</p>
        <p className='text-red-600 font-semibold'>Once the data is deleted, it will not be recovered!</p>
      </Modal>

    </div>
  );
}
