import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import ClientModal from '../Modals/ClientModal';
import ProductModal from '../Modals/ProductModal';
import TermsCondModal from '../Modals/Terms&CondModal';
import { request } from '../../util/fetchAPI';
import { LoadingOutlined } from '@ant-design/icons';
import { MdAddCircle } from "react-icons/md";
import { Spin } from 'antd';
import "./QuotationEditForm.css"

export default function QuotationEditForm() {


  const [state, setState] = useState({ QuoteDate: "", Subject: "" });
  const [submitButton, setSubmitButton] = useState(true);
  const [productRows, setProductsRows] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isTermCondModalOpen, setIsTermCondModalOpen] = useState(false);
  const [termCond, setTermCond] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const { id } = useParams();

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const handleRowInputChange = (index, field, value) => {
    const updatedRows = [...productRows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value,
    };
    setProductsRows(updatedRows);
  };

  const showModal = () => {
    setIsModalOpen(true);

  };
  const showProductModal = (index) => {
    setIsProductModalOpen(true)
    setSelectedRowIndex(index);
  };

  const showTermCondModal = (index) => {
    setIsTermCondModalOpen(true)
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsProductModalOpen(false)
    setIsTermCondModalOpen(false)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsProductModalOpen(false)
    setIsTermCondModalOpen(false)
  };

  const selectClient = (client) => {
    const data = {
      _id: client._id,
      ClientId: client.ClientId,
      ClientName: client.ClientName,
    }
    setSelectedClient(data)
    handleCancel()
  }


  const handleRowClick = (id) => {
    setSelectedRowId(selectedRowId === id ? null : id);
  };

  const rowInsertHandler = () => {
    setProductsRows(
      [
        ...productRows,
        {
          id: productRows.length + 1,
          ProductCode: "",
          ProductName: "",
          ProductUnit: "",
          Specs: "",
          rate: "",
          tax: "",
          taxRate: "",
          amount: "",
        }
      ]
    )
  }

  const rowDeleteHandler = (id) => {
    const deletRow = productRows.filter((row) => (row.id ? row.id : row._id) !== id)
    setProductsRows(deletRow)
  }


  const productSelect = (product) => {

    const newProducts = [...productRows];
    newProducts[selectedRowIndex] = {
      ...newProducts[selectedRowIndex],
      _id: product._id,
      ProductCode: product.ProductCode,
      ProductName: product.ProductName,
      ProductUnit: product.ProductUnit,
      Specs: product.Specs,
      rate: product.rate,
    };

    setProductsRows(newProducts);
    handleCancel(); // Close the modal
  };



  const amountCalcu = (index, field, value) => {
    const updatedRows = [...productRows];
    let qty = parseFloat(updatedRows[index].qty) || 0;
    let rate = parseFloat(updatedRows[index].rate) || 0;
    let tax = parseFloat(value) || 0; // Use value directly
    let taxRate = parseFloat(updatedRows[index].taxRate) || 0;

    // Update the specific field
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value
    };

    // Recalculate the tax and amount
    if (field === "rate" || field === "qty" || field === "tax") {
      rate = parseFloat(updatedRows[index].rate) || 0;
      qty = parseFloat(updatedRows[index].qty) || 0;
      tax = parseFloat(updatedRows[index].tax) || 0;
      taxRate = rate * (tax / 100);  // Assuming tax is a percentage

      updatedRows[index].taxRate = taxRate;
      updatedRows[index].amount = qty * (rate + taxRate);
    }
    setProductsRows(updatedRows);
  };

  const calculateGrandTotal = () => {
    const total = productRows.reduce((sum, row) => {
      const amount = parseFloat(row.amount || (((row.rate * (row.tax / 100))+ row.rate) * row.qty)) || 0;
      return sum + amount;
    }, 0);
    setGrandTotal(total);
  };

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,  // Allow decimal formatting if needed
    });
    return formatter.format(number);
  };


  const selectTermCond = (isChecked, NewTermCond) => {
    if (isChecked) {
      // Add the selected term condition
      setTermCond((prevTermCond) => [
        ...prevTermCond,
        {
          _id: NewTermCond._id,
          description: NewTermCond.description,
        },
      ]);
    } else {
      // Remove the unselected term condition
      setTermCond((prevTermCond) =>
        prevTermCond.filter((term) => term._id !== NewTermCond._id)
      );
    }
  };


  const quoteUpdateHandler = async (e) => {
    e.preventDefault()

    let productData = []
    if (!selectedClient) {
      return errorNotifier("Client Field Is Empty")
    }

    if (!productRows && productRows.length === 0) {
      return errorNotifier("Product Field Is Empty")
    }
    productData = productRows.map(({ _id, ProductId, Specs, ProductUnit, qty, rate, tax }) => ({
      _id: _id ? _id : ProductId._id,
      Specs,
      ProductUnit,
      qty,
      rate,
      tax
    }))

    let termConId = []
    termConId = termCond && termCond.map(({ _id }) => ({
      _id,
    }))

    const payload = {
      ...state,
      ClientId: selectedClient._id,
      productData,
      termConId
    }

    try {
      setSubmitButton(false)
      const data =  await request("/quote/updateQuote", "PUT", {
        "Content-Type": "application/json"
      }, { payload })
      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setProductsRows([])
        setSelectedRowId(null)
        setSelectedRowIndex(null)
        setTermCond(null)
        setSelectedClient(null)
      } else {
        errorNotifier(data.data.message)
      }
    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  const fetchQuote = async (id) => {
    try {
      const data = await request(`/Quote/getOnlyQuoteBy/${id}`, "GET")
      setState(data.QuoteData)
      setSelectedClient(data.QuoteData?.ClientId)
      setTermCond(data.QuoteData?.termCond)
      setProductsRows(data.QuoteProduct)

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }


  useEffect(() => {
    fetchQuote(id)
  }, [id])

  useEffect(() => {
    calculateGrandTotal();
  }, [productRows]);


  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Update Quotation</h1>
      <div className='formContiner'>
        <form onSubmit={quoteUpdateHandler}>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Quotation No.#</label>
              <input type="text" name="QuoteNo" value={state?.QuoteNo} onChange={stateHandler} />
            </div>

            <div>
              <label htmlFor="">Date</label>
              <input type="date" name="QuoteDate" value={state?.QuoteDate} onChange={stateHandler} required />
            </div>

          </div>

          <div className='inputBox'>
            <div>

              <label htmlFor="">Client Id</label>
              <input type="text" name="" id="" value={selectedClient?.ClientId}
                onClick={() => { showModal() }} readOnly required />
            </div>

            <div>
              <label htmlFor="">Client Name</label>
              <input type="text" name="" id="" value={selectedClient?.ClientName} disabled />
            </div>

          </div>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Subject</label>
              <textarea type="text" name="Subject" className='p-2'
                value={state.Subject}
                onChange={stateHandler} required />
            </div>

          </div>

          <div className='Buttons'>
            <span onClick={() => rowInsertHandler()}>Add</span>
            <span onClick={() => { rowDeleteHandler(selectedRowId) }}>Delete</span>
          </div>

          <div className="overflow-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3 w-10">Sr.</th>
                  <th scope="col" className="px-4 py-3 w-44">Product Code</th>
                  <th scope="col" className="px-4 py-3 w-96">Product Name</th>
                  <th scope="col" className="px-4 py-3 w-52">Specs</th>
                  <th scope="col" className="px-4 py-3 w-16">Unit</th>
                  <th scope="col" className="px-4 py-3 w-16">Qty</th>
                  <th scope="col" className="px-4 py-3 w-28">Rate</th>
                  <th scope="col" className="px-4 py-3 w-28">Tax%</th>
                  <th scope="col" className="px-4 py-3 w-28">Tax</th>
                  <th scope="col" className="px-4 py-3 w-32">Amount</th>
                </tr>
              </thead>

              <tbody>
                {productRows && productRows.map((data, index) => (
                  <tr key={index} className={
                    selectedRowId === (data.id ? data.id : data._id)
                      ? "bg-indigo-800 text-white"
                      : "text-black"
                  }
                    onClick={() => handleRowClick(data.id ? data.id : data._id)}>
                    <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1}</td>
                    <th scope="row" className="px-2 py-2 w-44 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400"
                      onDoubleClick={() => showProductModal(index)}>
                      {data.ProductCode}</th>

                    <td className="w-96 px-2 py-2 border-2 border-gray-400"
                      onDoubleClick={() => showProductModal(index)}>{data.ProductName}</td>

                    <td className="w-52 px-2 py-2 border-2 border-gray-400">

                      <input
                        type="text"
                        className='w-52 p-2 rounded-md text-black text-sm'
                        name="Specs"
                        value={data.Specs || ""}
                        onChange={(e) => handleRowInputChange(index, "Specs", e.target.value)}
                      />

                    </td>
                    <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.ProductUnit}</td>

                    <td className="px-2 py-2 border-2 border-gray-400 w-16">
                      <input type="Number" className='w-16 p-2 rounded-md text-black' name="qty" value={data.qty} onChange={(e) => amountCalcu(index, "qty", e.target.value)} />
                    </td>

                    <td className="px-2 py-2 border-2 border-gray-400 w-28">
                      <input type="Number" className='w-28 p-2 rounded-md text-black' name="rate" value={data.rate} onChange={(e) => amountCalcu(index, "rate", e.target.value)} />
                    </td>

                    <td className="w-32 px-2 py-2 border-2 border-gray-400">
                      <input
                        type="Number"
                        className='w-28 p-2 rounded-md text-black'
                        name="tax"
                        value={data.tax || ""}
                        onChange={(e) => amountCalcu(index, "tax", e.target.value)}
                      />
                    </td>


                    <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.taxRate ? data.taxRate : (data.rate * (data.tax / 100)))}.00</td>

                    <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.amount ? data.amount :  (((data.rate * (data.tax / 100))+ data.rate) * data.qty)) || 0}.00</td>

                  </tr>
                ))}
              </tbody>

            </table>
          </div>

          <div className="flex justify-end mt-4">
            <h3 className="text-xl font-semibold">Grand Total: {formatNumberToInternational(grandTotal)}.00</h3>
          </div>


          <div className='termCond'>
            <h1 className="flex text-2xl gap-2 cursor-pointer"> <span>Terms & Condition </span><MdAddCircle onClick={showTermCondModal} /></h1>
            <ul style={{ listStyle: "none" }}>
              {termCond && termCond.map((data, index) => (
                <li>{++index} - {data.description}</li>))}
            </ul>
          </div>
          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Update" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />} /> </>
          }</button>
        </form>
      </div>


      <ClientModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectClient={selectClient}
      />

      <ProductModal
        isProductModalOpen={isProductModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        productSelect={productSelect}
      />

      <TermsCondModal
        isTermCondModalOpen={isTermCondModalOpen}
        accountName={"Quotations"}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectTermCond={selectTermCond}
      />

    </div>
  )
}


