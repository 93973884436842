import React from 'react'
import AppRoutes from '../AppRoutes/AppRoutes'

export default function Content () {
  return (
    <div>
        <AppRoutes/>
    </div>
  )
}
