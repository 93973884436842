import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { request } from '../../util/fetchAPI'
import MyProfileForm from './MyProfileForm'
import MyProfileView from './MyProfileView'

export default function MyProfile() {

  const [isCompanyExit, setIsCompanyExit] = useState()
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const fetchCompany = async () => {
    try {
      const data = await request(`/company/getCompany?createdBy=${user._id}`, "GET")
      console.log(data)
      setIsCompanyExit(data)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchCompany()
  },[])

  return (
    <div>
      {isCompanyExit ?
        <MyProfileView isCompanyExit={isCompanyExit}/>
        :
        <MyProfileForm/>
      }
    </div>
  )
}
