import React, { useState } from 'react'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function UserManagerForm() {

  const [state, setState] = useState()
  const [submitButton, setSubmitButton] = useState(true)

  const stateHandler = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }


  const UserHandler = async (e) => {
    e.preventDefault()
    try {
      setSubmitButton(false)
      const data = await request("/user/registerNewUser", "POST", {
        "Content-Type": "application/json"
      }, { ...state })
      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setTimeout(()=>{
          window.location.reload()
        },[1000])
      } else {
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
    setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  const ShowPassword = () => {
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Add User</h1>
      <div className='formContiner'>
        <form onSubmit={UserHandler}>

          <div className='inputBox'>
            <div>
              <label htmlFor="">User Id</label>
              <input type="text" name="UserId" id="" onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">User Type</label>
              <select name="UserType" id="" required onChange={stateHandler}>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>

          </div>

         <div className='inputBox'>

            <div>
              <label htmlFor="">password</label>
              <input type="password" name="password" id="myInput" onChange={stateHandler} required />
            </div>

          </div>

          <div className='pt-2'>
            Show Password
            <input
              type="checkbox"
              className="form-check-input ml-1 "
              id="gridCheck"
              onClick={ShowPassword}
            />
          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

        </form>
      </div>
    </div>
  )
}
