import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import Content from './components/Content/Content';
import Login from './components/Login/Login';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

export default function App() {
const { user } = useSelector((state) => state.auth);


  return (
    <div>
      <Routes>
        {/* Public Route */}
        <Route path="/Login" element={user ? <Navigate to="/" /> : <Login />} />
        </Routes>

          {!user
          ?
          <Navigate to={"/Login"}/>
          :
          <>
          <Header/>
          <Content/>
          </>}

    </div>

  );
}
