import { useState } from "react"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { useDispatch } from 'react-redux'
import { request } from '../../util/fetchAPI'
import { login } from '../../redux/authSlice'
import { useNavigate } from "react-router-dom"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Logo from "../../assets/Logo.jpg"
import "./Login.css"

export default function Login() {

  const [state, setState] = useState()
  const [submitButton, setSubmitButton] = useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleState = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  //Register Products Catagories
  const handleRegister = async (e) => {
    e.preventDefault()
    setSubmitButton(false)
    try {
      const data = await request("/user/UserLogin", "POST", {
        "Content-Type": "application/json"
      }, { ...state })
      if (data.status !== 200) {
        errorNotifier(data.data.message)
        setSubmitButton(true)
      } else {
        dispatch(login(data.data))
        setSubmitButton(true)
        navigate("/")
      }


    } catch (error) {
      console.log(error)
          setSubmitButton(true)
          errorNotifier("Un-Expected Error")
    }
  }

  return (
    <div>
         <ToastContainer />
        <div className="h-[100vh] w-[100%] flex justify-center items-center bg-slate-50">

        <div className="flex h-[65vh] w-[70%] m-2 justify-center items-center px-6 py-12 lg:px-8 border-2 border-gray-500 rounded-lg Form">

          <div className="sm:mx-auto sm:w-full sm:max-w-sm">

          <h2 className="mx-auto w-fit p-2 bg-white text-center text-2xl font-bold leading-9 tracking-tight text-black">
              Login To Your Account
            </h2>

              <img
                alt="Your Company"
                src={Logo}
                className="mx-auto h-50vh w-full"
                />
      <div className="mb-5">
      <p className="mx-auto w-fit text-center text-lg text-black">
          For any query feel free to contact us.
            </p>
          <p className="mx-auto w-fit text-center text-lg text-black">
          Email : m.arslan.soft.solution@gmail.com
            </p>
          <p className="mx-auto w-fit text-center text-lg text-black">
         Contact : 0317-1505390
            </p>

      </div>

          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleRegister} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900" >
                  User Id
                </label>
                <div className="mt-2">
                  <input

                    name="UserId"
                    type="text"
                    required
                    autoComplete="email"
                    className="block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState} />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    autoComplete="current-password"
                    className="block w-full rounded-md border-2 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleState} />
                </div>
              </div>

              <div className="w-full">

              <button className='SubButton w-full' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>
              </div>
            </form>

          </div>
        </div>
    </div>
    </div>



  )
}