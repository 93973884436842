import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {Link} from "react-router-dom"
import { BsMenuAppFill } from "react-icons/bs";
import { LuClipboardList } from "react-icons/lu";
import { AiOutlineProduct } from "react-icons/ai";
import { TbFileInvoice } from "react-icons/tb"
import { TbReportAnalytics } from "react-icons/tb";
import { LuUserCog } from "react-icons/lu";
import { IoMdCash } from "react-icons/io";
import { MdOutlineReceiptLong } from "react-icons/md";
import { MdOutlineSettings } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { request } from '../../util/fetchAPI';
import Sim from "../../assets/Sim.png"
import MasterCard from "../../assets/MasterCard.png"
import "./DashBorad.css"

export default function DashBorad() {

  const [companyName, setCompanyName] = useState("")
  const { user } = useSelector((state) => state.auth);

  const fetchCompany = async()=>{
    try {

      const data = await request(`/company/getCompany?createdBy=${user._id}`, "GET")
      setCompanyName(data)

    } catch (error) {
      console.log(error)

    }
  }

  useEffect(()=>{
    fetchCompany()
  },[])
  return (
    <div className='DashContainer'>

      <div className='creditCard'>
        <div className='flex justify-between'>
        <h4>Welcome</h4>
        <h4 className='text-lime-300 cursor-pointer font-semibold'>Check Detail</h4>
        </div>

        <img src={Sim} alt="" className=' w-16 h-10 rounded-md m-2'/>
        <h5 className='text-base font-semibold'>User Name : {user?.UserId}</h5>
        <h5 className='text-base font-semibold'>Company   : {companyName?.CompanyName}</h5>
        <h5 className='text-base font-semibold'>Balance   : 0.00</h5>
        <img src={MasterCard} alt="" className='flex w-20 h-12 mt-2'/>

      </div>


      <div>
        <h2>Manage</h2>
        <div className='ManagementCards'>

            <span>
            <Link to="/CreateClient">
            <CgProfile className='icon'/>Clients
            </Link>
            </span>

            <span>
            <Link to="/Clients">
            <LuClipboardList className='icon'/>Clients List
            </Link>
            </span>

            <span>
              <Link to="/CreateProduct">
              <AiOutlineProduct className='icon'/>Products
              </Link>
            </span>

            <span>
              <Link to="/Products">
              <LuClipboardList className='icon'/>Products List
              </Link>
            </span>

        </div>

        <div>
          <h2>Discover</h2>
          <div className='ManagementCards'>

            <span>
            <Link to="/CreateQuotation">
            <BsMenuAppFill className='icon'/>  Quotation
            </Link>
            </span>

            <span>
            <Link to="/Quotations">
             <LuClipboardList className='icon'/> Quotation List
             </Link>
            </span>

            <span>
            <Link to="/CreateInvoice">
             <TbFileInvoice className='icon'/> Invoice
             </Link>
            </span>

            <span>
            <Link to="/Invoices">
            <LuClipboardList className='icon'/>  Invoice list
            </Link>
            </span>

          </div>
        </div>
        <div>
          <h2>Payment</h2>
          <div className='ManagementCards'>

            <span>
            <Link to="/CreatePaymentReceipt">
            <IoMdCash className='icon'/> Payments
            </Link>
            </span>

            <span>
            <Link to="/ReciptsList">
             <MdOutlineReceiptLong  className='icon'/> Receipts
             </Link>
            </span>


          </div>
        </div>
      {/*   <div>
          <h2>Reports</h2>
          <div className='ManagementCards'>

            <span>
            <Link to="/CreateQuotation">
            <TbReportAnalytics className='icon'/> Client Wise
            </Link>
            </span>

            <span>
            <Link to="/Quotations">
             <TbReportAnalytics className='icon'/> Product Wise
             </Link>
            </span>

            <span>
            <Link to="/CreateInvoice">
             <TbReportAnalytics className='icon'/> Quote Wise
             </Link>
            </span>

            <span>
            <Link to="/Invoices">
            <TbReportAnalytics className='icon'/> Invoice Wise
            </Link>
            </span>

          </div>
        </div> */}

        <h2>Settings</h2>
        <div className='ManagementCards'>

        {user.UserType === "admin" &&
          <span>
            <Link to="/UserManager">
            <LuUserCog className='icon'/>User Manager
            </Link>
        </span>}

        <span>
            <Link to="/MyProfile">
            <CgProfile className='icon'/>Profile
            </Link>
        </span>

        <span>
            <Link to="/Settings">
            <MdOutlineSettings className='icon'/>Setting
            </Link>
        </span>

        </div>

      </div>

    </div>
  )
}
