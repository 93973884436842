import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import ClientModal from '../Modals/ClientModal';
import { request } from '../../util/fetchAPI';
import { LoadingOutlined } from '@ant-design/icons';
import { MdAddCircle } from "react-icons/md";
import { Spin } from 'antd';
import "./ReceiptForm.css"
import InvoiceModal from '../Modals/InvoiceModal';



export default function ReceiptForm() {

  const [state, setState] = useState()
  const [isError, SetIsError] = useState(false)
  const [prefix, setPrefix] = useState({ prefix: "", Id: "", currentId: "" })
  const [submitButton, setSubmitButton] = useState(true)
  const [invoiceRow, setInvoiceRow] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedClient, setSelectedClient] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInvOpen, setModalInvOpen] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const showModal = () => {
    setIsModalOpen(true);

  };

  const showInvModal = () => {
    setModalInvOpen(true);

  };

  const handleOk = () => {
    setIsModalOpen(false);
    setModalInvOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalInvOpen(false);
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const selectClient = (client) => {
    const data = {
      _id: client._id,
      ClientId: client.ClientId,
      ClientName: client.ClientName,
    }
    setSelectedClient(data)
    handleCancel()
  }


  const handleRowClick = (id) => {
    setSelectedRowId(selectedRowId === id ? null : id);
  };

  const rowDeleteHandler = (id) => {
    const deletRow = invoiceRow.filter((row) => row.id !== id)
    setInvoiceRow(deletRow)
  }

  const selectInvoice = (isChecked, invoice) => {
    if (isChecked) {
      // Add the selected term condition
      setInvoiceRow((preinvoice) => [
        ...preinvoice,
        {
          _id: invoice._id,
          id:invoiceRow.length + 1,
          InvoiceNo:invoice.InvoiceNo,
          InvoiceDate:invoice.InvoiceDate,
          Subject:invoice.Subject,
          refPO:invoice.refPO,
          totalAmount:invoice.totalAmount
        },
      ]);
    } else {
      // Remove the unselected term condition
      setInvoiceRow((preinvoice) =>
        preinvoice.filter((data) => data._id !== invoice._id)
      );
    }
  };



  const calculateGrandTotal = () => {
    const total = invoiceRow.reduce((sum, row) => {
      const amount = parseFloat(row.totalAmount) || 0;
      return sum + (amount);
    }, 0);
    setGrandTotal(total);
  };

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const fetchPrerfixData = async () => {
    try {

      const data = await request(`/settings/getPrefixIdBy?accountName=${"Receipt"}&createdBy=${user._id}`, "GET")
      setPrefix(data)
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }


  const paymentReceiptHandler = async (e) => {
    e.preventDefault()

    if(!invoiceRow || invoiceRow.length === 0){
      return  errorNotifier("Invoice Field Required")
    }

    if(state.AmountRecevied < grandTotal){
      SetIsError(true)
      return  errorNotifier("Amount Recevied Is Less Then Invoices Grand Total")
    }

    const InvoiceId = invoiceRow.map(({_id})=>({
      _id
    }))

    const payload = {
      ReceiptNo:prefix?.prefix
      + "-" + prefix?.currentId,
      ...state,
      createdBy:user._id,
      ClientId:selectedClient._id,
      InvoiceId : InvoiceId,
    }
    try {
      setSubmitButton(false)
      const data =  await request("/receipt/createPaymentReceipt", "POST", {
        "Content-Type": "application/json"
      },{...payload})
      setSubmitButton(true)
      if(data.status === 200){
        successNotifier(data.data.message)
        setInvoiceRow([])
        setSelectedClient(
          {_id:"",
          ClientId:"",
          ClientName:""})
      }else{
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(() => {
    fetchPrerfixData()
  }, [])

  useEffect(()=>{
    setInvoiceRow([])
  },[selectedClient])

  useEffect(() => {
    calculateGrandTotal()
  }, [invoiceRow])


  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Create Payment Receipt</h1>
      <div className='formContiner'>
        <form onSubmit={paymentReceiptHandler}>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Receipt No.#</label>
              <input type="text" name="ReceiptNo" value={prefix ? prefix?.prefix
                + " - " + prefix?.currentId : ''} required disabled />
            </div>
            <div>
              <label htmlFor="">Receving Date</label>
              <input type="date" name="RecevingDate" value={state?.RecevingDate} onChange={stateHandler} required />
            </div>
          </div>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Client Id</label>
              <input type="text" name="" id="" value={selectedClient?.ClientId}
                onClick={() => { showModal() }} readOnly required />
            </div>

            <div>
              <label htmlFor="">Client Name</label>
              <input type="text" name="" id="" value={selectedClient?.ClientName} disabled />
            </div>

          </div>

          <div className='inputBox'>
            <div>

              <label htmlFor="">Payment Method</label>
              <select name="MOP" id="" required onChange={stateHandler}>
                <option defaultValue hidden>Chose...</option>
                <option value="Cheque" >Cheque</option>
                <option value="Bank_Draft">Bank Draft</option>
                <option value="Cash">Cash</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <div>
              <label htmlFor="">Payment Reference (Any)</label>
              <input type="text" name="Ref" className='p-2' value={state?.Ref} onChange={stateHandler} />
            </div>

          </div>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Amount Recevied</label>
              <input type="Number" name="AmountRecevied" className='p-2' value={state?.AmountRecevied} onChange={stateHandler} required />
              <div className={isError ?'text-red-600' : "hidden"}>
                Amount Recevied Is Less Then Grand Total
              </div>
            </div>

            <div>
              <label htmlFor="">Remarks</label>
              <textarea type="text" name="Remarks" className='p-2' value={state?.Remarks} onChange={stateHandler} />
            </div>
          </div>

          <div className='Buttons'>
            <span onClick={() => {
              showInvModal() }}>Add</span>

          </div>

          <div className="overflow-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3 w-10">Sr.</th>
                  <th scope="col" className="px-4 py-3 w-44">Invoice No</th>
                  <th scope="col" className="px-4 py-3 w-44">Invoice Date</th>
                  <th scope="col" className="px-4 py-3 w-96">Subject</th>
                  <th scope="col" className="px-4 py-3 w-52">Ref PO</th>
                  <th scope="col" className="px-4 py-3 w-32">Amount</th>
                  <th scope="col" className="px-4 py-3 w-32">Actions</th>
                </tr>
              </thead>

              <tbody>
                {invoiceRow && invoiceRow.map((data, index) => (
                  <tr key={index} className={
                    selectedRowId === data.id ? "bg-indigo-800 text-white" : "text-black"} onClick={() => handleRowClick(data.id)}>
                    <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1}</td>
                    <th scope="row" className="px-2 py-2 w-44 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400" onDoubleClick={() => showInvModal()}>{data.InvoiceNo}</th>
                    <td className="w-96 px-2 py-2 border-2 border-gray-400" onDoubleClick={() => showInvModal()}>{data.InvoiceDate}</td>
                    <td className="w-52 px-2 py-2 border-2 border-gray-400">
                    {data.Subject}
                    </td>
                    <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.refPO}</td>
                    <td className="px-2 py-2 border-2 border-gray-400 w-16 font-bold">
                    {formatNumberToInternational(data.totalAmount)}.00
                    </td>
                    <td className="px-2 py-2 border-2 border-gray-400 w-16 font-bold cursor-pointer">
                   {data._id ?
                    <button onClick={()=>showInvModal()}>Edit</button>
                    :
                    <button  onClick={() => { rowDeleteHandler(selectedRowId) }}>Delete</button>}

                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>

          <div className="flex justify-end mt-4">
            <h3 className="text-xl font-semibold">Grand Total: {formatNumberToInternational(grandTotal)}.00</h3>
          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />} /> </>
          }</button>
        </form>
      </div>


      <ClientModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectClient={selectClient}
      />

      <InvoiceModal
        isModalInvOpen={isModalInvOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectInvoice={selectInvoice}
        ClientId={selectedClient?._id}
        isChecked={false}
      />


    </div>
  )
}


