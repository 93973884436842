/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast,Bounce  } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { request } from '../../util/fetchAPI';
import "./InvoiceView.css"

export default function InvoiceView() {

  const [invoiceData, setInvoiceData] = useState()
  const [productData, setProductsData] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [termCond, setTermCond] = useState([])
  const {id} = useParams()

  const errorNotifier = (data)=>{
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
  }

  const handleRowClick = (id) => {
    setSelectedRowId(selectedRowId === id ? null : id);
  };

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const calculateGrandTotal = () => {
    return productData.reduce((acc, data) => {
      const totalForItem = ((data.rate * (data.tax / 100)) + data.rate) * data.qty;
      return acc + totalForItem;
    }, 0);
  };


  const fetchInvoice =async (id)=>{
    try {

      const data = await request(`/invoice/getInvoiceBy/${id}`,"GET")
      setInvoiceData(data.InvoiceData)
      setProductsData(data.InvoiceProduct)
      setTermCond(data?.QuoteData?.termCond)
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(()=>{
    fetchInvoice(id)
  },[id])



  return (
    <div className='QuoteContainer'>
  <ToastContainer />
      <h1>View Invoice</h1>
      <div className='formContiner'>
        <form >
        <div className='inputBox'>
          <div>
          <label htmlFor="">Invoice No.#</label>
          <input type="text" value={invoiceData?.InvoiceNo} readOnly/>
          </div>
          <div>
          <label htmlFor="">Date</label>
          <input type="date" value={invoiceData?.InvoiceDate} readOnly/>
          </div>

          </div>

          <div className='inputBox'>
          <div>

            <label htmlFor="">Invoice Type</label>
            <input type="text" value={invoiceData?.QuoteId ? "Quotation Linked" : "Quick Invoice"} readOnly/>
            </div>

              {invoiceData?.QuoteId &&
              <div>
              <label htmlFor="">Quotation</label>
              <input type="text" value={invoiceData?.QuoteId?.QuoteNo + " - " + invoiceData?.QuoteId?.QuoteDate} readOnly/>
              </div>
              }

          </div>
          <div className='inputBox'>

          <div>
            <label htmlFor="">Client Id</label>
            <input type="text"
            value={invoiceData?.ClientId?.ClientId} readOnly/>
          </div>

          <div>
            <label htmlFor="">Client Name</label>
            <input type="text"
            value={invoiceData?.ClientId?.ClientName} readOnly/>
          </div>

          </div>

          <div className='inputBox'>
            <div>
            <label htmlFor="">Subject</label>
            <textarea type="text" className='p-2' value={invoiceData?.Subject} readOnly/>
            </div>

            {invoiceData?.QuoteId &&
            <div>
            <label htmlFor="">Ref/Purchase Order</label>
            <input type="text" className='p-2' value={invoiceData?.refPO} readOnly/>
            </div>
            }

          </div>
          <div className='inputBox mb-5'>
            <div>
            <label htmlFor="">Current Status</label>
            <input type="text" className='p-2' value={invoiceData?.isFinal ? "Paid" : "Unpaid"} readOnly/>
            </div>

          </div>


          <div className="overflow-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">#</th>
                  <th scope="col" className="px-4 py-3">Pr. Code</th>
                  <th scope="col" className="px-4 py-3">Product</th>
                  <th scope="col" className="px-4 py-3">Specs</th>
                  <th scope="col" className="px-4 py-3">Unit</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Qty</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Rate</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Tax%</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Tax</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Amount</th>
                </tr>
              </thead>


          <tbody>
          {productData.map((data, index)=>(
             <tr key={index} className={selectedRowId === index? "bg-indigo-800 text-white" : "text-black"} onClick={() => handleRowClick(index)}>
              <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1 }</td>
            <th scope="row" className="px-2 py-2 w-20 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400">
            {data.ProductId?.ProductCode}</th>
              <td className="px-2 py-2 border-2 border-gray-400 w-96">{data.ProductId?.ProductName}</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-52">{data.Specs}</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.ProductId?.ProductUnit}</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.qty}</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-10">{formatNumberToInternational(data.rate)}.00</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-10">{formatNumberToInternational(data.tax)}%</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-10">{formatNumberToInternational(data.rate * (data.tax/100))}.00</td>
              <td className="px-2 py-2 border-2 border-gray-400 w-10">
              {formatNumberToInternational(((data.rate * (data.tax/100)) + data.rate)* data.qty)}.00</td>
          </tr>
          ))

          }
          </tbody>

        </table>
        </div>

        <div className="flex justify-end mt-4">
              <h3 className="text-xl font-semibold">
                Grand Total: {formatNumberToInternational(calculateGrandTotal())}.00
              </h3>
            </div>


        <div className='mt-10'>
        <h1 className="flex text-2xl gap-2 cursor-pointer">Terms & Condition</h1>
        <ul style={{listStyle:"none"}}>
         {termCond && termCond.map((data, index)=>(
          <li>{++index} - {data.description}</li>))}
        </ul>

      </div>

        </form>
      </div>

    </div>
  )
}


