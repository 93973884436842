/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../redux/authSlice"
import { Link, useNavigate } from 'react-router-dom'
import { CgProfile } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { MdPassword } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import "./Header.css"

export default function Header() {

  const [showModel, setShowModel] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className='navContainer'>
        <nav>
          <h1><Link to="/">Quote & Invoice Manager</Link></h1>

            <span onClick={()=>setShowModel((prev)=>!prev)}><CgProfile className='profile'/></span>
            {showModel &&
              <div className='absolute m-3 p-3 right-0 w-60 h-32 bg-white shadow-xl rounded-lg text-black cursor-pointer'>
                <IoMdClose className='absolute right-1 m-1 hover:text-red-700'onClick={()=>setShowModel((prev)=>!prev)}/>
                <ul className='flex flex-col gap-2 mt-7 text-lg'>
                  <li className='flex hover:text-indigo-700'
                  onClick={()=>{navigate("/changePwd");
                  setShowModel(false)}}>Change Password <MdPassword className='text-3xl ml-2'/></li>
                  <li className='flex hover:text-indigo-700'
                  onClick={()=>{handleLogout();setShowModel(false)}}>Logout <IoLogOut className='text-3xl ml-2'/></li>
                </ul>
              </div>
            }
        </nav>
    </div>
  )
}

