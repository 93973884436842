/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import QuoteRateHis from '../Tabs/QuoteRateHis';
import InvoiceRateHis from '../Tabs/InvoiceRateHis';


export default function ProductsView() {

    const [productData , setProductsData] = useState('')
    const [quoteRateHistory, setQuoteRateHistory] = useState([])
    const [invoiceRateHistory, setInvoiceRateHistory] = useState([])
    const {id} = useParams()

    const errorNotifier = (data) => {
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }


    const fetchProduct = async (id)=>{
        try {
            const data = await request(`/product/getProductBy/${id}`,"GET")
            setProductsData(data.productData)
            setQuoteRateHistory(data.quoteRateHistory)
            setInvoiceRateHistory(data.invoiceRateHistory)

        } catch (error) {
          console.log(error)
          errorNotifier("Un-Expected Error")
        }
    }

    useEffect(()=>{
      fetchProduct(id)
    },[])

  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>View Product</h1>
      <div className='formContiner'>
        <form>
        <div className='inputBox'>
          <div>
          <label htmlFor="">Product Code</label>
          <input type="text" className='bg-white' name="ProductCode" value={productData.ProductCode} disabled/>
          </div>
          <div>
          <label htmlFor="">Product Name</label>
          <input type="text" className='bg-white' name="ProductName" value={productData.ProductName} disabled/>
          </div>

          </div>

          <div className='inputBox'>
          <div>
            <label htmlFor="">Unit</label>
            <input type="text" className='bg-white' name="ProductUnit" value={productData.ProductUnit} disabled/>
            </div>

            <div>
            <label htmlFor="">Specs</label>
            <textarea type="text" className='bg-white p-2' name="Specs"  value={productData.Specs} disabled/>
            </div>

            </div>

            <div className='inputBox'>
            <div>
            <label htmlFor="">Rate</label>
            <input type="Number" className='bg-white' name="rate" id="" value={productData.rate + ".00"} disabled/>
            </div>
            </div>
        </form>
      </div>
      <div className='w-[100%] p-10'>
        <Tabs
          className='m-auto'
          defaultActiveKey="1"
          items={[
            {
              label: 'Quotations Rate History',
              key: '1',
              children: <QuoteRateHis quoteRateHistory={quoteRateHistory} />,
            },
            {
              label: 'Invoices Rate History',
              key: '2',
              children: <InvoiceRateHis invoiceRateHistory={invoiceRateHistory}/>,
            },

          ]}
        />
    </div>
    </div>
  )
}
