/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {useParams} from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { Tabs } from 'antd';
import Quotation from '../Tabs/Quotation';
import Invoice from '../Tabs/Invoice';

export default function ClientView() {

  const [clientData , setClientData] = useState('')
  const [clientQuotation , setClientQuotations] = useState([])
  const [clientInvoices , setClientInvoices] = useState([])
  const [clientBalance , setClientBalance] = useState()
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)
  const {id} = useParams()

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const fetchClient = async (id) => {
    try {

      const data = await request(`/client/getClientBy/${id}?createdBy=${user._id}`, "GET")
      console.log(data)
      setClientData(data.clientData)
      setClientQuotations(data.clientQuotations)
      setClientInvoices(data.clientInvoices)
      setClientBalance(data.grandAmount)
      if (data.status === 200) {
        successNotifier("Client Has Fetched")
      } else {
        errorNotifier(data.data?.message)
      }

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(()=>{
    fetchClient(id)
  },[id])

  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>View Client Information</h1>
      <div className='formContiner'>
        <form>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Client Id</label>
              <input type="text" name="ClientId" value={clientData.ClientId} className="bg-white" disabled/>
            </div>

            <div>
              <label htmlFor="">Client Name</label>
              <input type="text" name="ClientName" value={clientData.ClientName}  className="bg-white" disabled/>
            </div>

          </div>

         <div className='inputBox'>

            <div>
              <label htmlFor="">Contact Person</label>
              <input type="text" name="ContactPerson" value={clientData.ContactPerson}  className="bg-white" disabled/>
            </div>

            <div>
              <label htmlFor="">Email</label>
              <input type="text" name="Email" value={clientData.Email}  className="bg-white" disabled/>
            </div>

          </div>

          <div className='inputBox'>

            <div>
              <label htmlFor="">Contact</label>
              <input type="text" name="Contact" value={clientData.Contact}  className="bg-white" disabled/>
            </div>

            <div>
              <label htmlFor="">Address</label>
              <textarea type="text" name="Address" value={clientData.Address}  className="bg-white p-2" disabled/>
            </div>

          </div>
        </form>
      </div>

    <div className='w-[100%] p-10'>
        <Tabs
          className='m-auto'
          defaultActiveKey="1"
          items={[
            {
              label: 'Quotations',
              key: '1',
              children: <Quotation clientQuotation={clientQuotation} />,
            },
            {
              label: 'Invoices',
              key: '2',
              children: <Invoice clientInvoices={clientInvoices}/>,
            },
            {
              label: 'Balance',
              key: '3',
              children:<span className='font-bold text-xl'>{formatNumberToInternational(clientBalance)}.00</span>
            },
          ]}
        />
    </div>

    </div>
  )
}
