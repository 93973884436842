import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function ClientForm() {

  const [state, setState] = useState()
  const [prefix, setPrefix] = useState({prefix:"", Id:"", currentId:""})
  const [submitButton, setSubmitButton] = useState(true)
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const stateHandler = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const fetchPrerfixData = async()=>{
    try {
      const data = await request(`/settings/getPrefixIdBy?accountName=${"Client"}&createdBy=${user._id}`, "GET")
      setPrefix(data)
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  const ClientHandler = async (e) => {
    e.preventDefault()
    try {
      setSubmitButton(false)

      if(!user){
        return errorNotifier("Un Authorized Request")
      }

      const payload = {
        ClientId:prefix?.prefix
        +"-"+prefix?.currentId,
        createdBy:user._id,
        ...state,
      }

      const data = await request("/client/createClient", "POST", {
        "Content-Type": "application/json"
      }, { ...payload})
      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        fetchPrerfixData()
        setState({ClientName:"", ContactPerson:"", Email:"", Contact:"", Address:"" })
      } else {
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(()=>{
    fetchPrerfixData()
  },[])
  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Client Information</h1>
      <div className='formContiner'>
        <form onSubmit={ClientHandler}>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Client Id</label>
              <input type="text" name="ClientId" value={prefix ? (prefix?.prefix
                + " - "+prefix?.currentId) : ""} required disabled/>
            </div>

            <div>
              <label htmlFor="">Client Name</label>
              <input type="text" name="ClientName" value={state?.ClientName} onChange={stateHandler} required />
            </div>

          </div>

         <div className='inputBox'>

            <div>
              <label htmlFor="">Contact Person</label>
              <input type="text" name="ContactPerson" value={state?.ContactPerson} onChange={stateHandler}  />
            </div>

            <div>
              <label htmlFor="">Email</label>
              <input type="text" name="Email" className='p-2' value={state?.Email} onChange={stateHandler} required />
            </div>

          </div>

          <div className='inputBox'>

            <div>
              <label htmlFor="">Contact</label>
              <input type="text" name="Contact" value={state?.Contact} onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Address</label>
              <textarea type="text" name="Address" className='p-2' value={state?.Address} onChange={stateHandler}  />
            </div>

          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

        </form>
      </div>
    </div>
  )
}
