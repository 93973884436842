import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


export default function ProductForm() {

    const [state, setState] = useState()
    const [prefix, setPrefix] = useState({prefix:"", Id:"", currentId:""})
    const [submitButton, setSubmitButton] = useState(true)
    const { user } = useSelector((state) => state.auth);
    const {token} = useSelector((state)=>state.auth)

    const stateHandler = (e)=>{
        setState((prev)=>{
            return {...prev, [e.target.name] : e.target.value}
        })
    }

    const successNotifier = (data) => {
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }

    const errorNotifier = (data) => {
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }

    const fetchPrerfixData = async()=>{
      try {

        const data = await request(`/settings/getPrefixIdBy?accountName=${"Products"}&createdBy=${user._id}`, "GET")
        setPrefix(data)
      } catch (error) {
        console.log(error)
        errorNotifier("Un-Expected Error")
      }
    }

    const productHandler = async (e)=>{
        e.preventDefault()
        try {
          setSubmitButton(false)
          if(!user){
            return errorNotifier("Un Authorized Request")
          }

          const payload = {
            ProductCode:prefix?.prefix
            +"-"+prefix?.currentId,
            createdBy:user._id,
            ...state,
          }
            const data = await request("/product/createProduct","POST", {
                "Content-Type":"application/json"
            }, {...payload })
            setSubmitButton(true)
            if (data.status === 200) {
              successNotifier(data.data.message)
              fetchPrerfixData()
            } else {
              errorNotifier(data.data.message)
            }


        } catch (error) {
          console.log(error)
          setSubmitButton(true)
          errorNotifier("Un-Expected Error")
        }
    }

    useEffect(()=>{
      fetchPrerfixData()
    },[])

  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Create Product</h1>
      <div className='formContiner'>
        <form onSubmit={productHandler}>
        <div className='inputBox'>
          <div>
          <label htmlFor="">Product Code</label>
          <input type="text" name="Product_Code" value={prefix ? (prefix?.prefix
                + " - "+prefix?.currentId) : ""} required disabled/>
          </div>
          <div>
          <label htmlFor="">Product Name</label>
          <input type="text" name="ProductName" value={state?.ProductName} onChange={stateHandler} required/>
          </div>

          </div>

          <div className='inputBox'>
          <div>
            <label htmlFor="">Unit</label>
            <input type="text" name="ProductUnit" value={state?.ProductUnit} onChange={stateHandler} required/>
            </div>

            <div>
            <label htmlFor="">Specs</label>
            <textarea type="text" name="Specs" className='p-2' value={state?.Specs} onChange={stateHandler} />
            </div>

            </div>

            <div className='inputBox'>
            <div>
            <label htmlFor="">Rate</label>
            <input type="Number" min={1} name="rate" value={state?.rate} onChange={stateHandler} required/>
            </div>
            </div>

            <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

        </form>
      </div>
    </div>
  )
}
