/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function ClientEditForm() {

  const [state, setState] = useState()
  const [submitButton, setSubmitButton] = useState(true)
  const {id} = useParams()

  const stateHandler = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }


  const ClientUpdateHandler = async (e) => {
    e.preventDefault()
    try {
      setSubmitButton(false)
      const data = await request("/client/updateClient", "PUT", {
        "Content-Type": "application/json"
      }, { ...state } )
      setSubmitButton(true)
      if (data.status === 200) {
        setState(data)
        successNotifier("Data Updated")
      } else {
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
    setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  const fetchClient = async (id) => {
    try {
      const data = await request(`/client/getOnlyClientBy/${id}`, "GET")
      setState(data)
      if (data.status !== 200) {
        errorNotifier(data.data?.message)
      }

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(()=>{
    fetchClient(id)
  },[id])

  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Update Client Information</h1>
      <div className='formContiner'>
        <form onSubmit={ClientUpdateHandler}>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Client Id</label>
              <input type="text" name="ClientId" value={state?.ClientId} onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Client Name</label>
              <input type="text" name="ClientName" value={state?.ClientName} onChange={stateHandler} required />
            </div>

          </div>

         <div className='inputBox'>

            <div>
              <label htmlFor="">Contact Person</label>
              <input type="text" name="ContactPerson" value={state?.ContactPerson} onChange={stateHandler}  />
            </div>

            <div>
              <label htmlFor="">Email</label>
              <input type="text" name="Email"
               value={state?.Email} className='p-2' onChange={stateHandler} required />
            </div>

          </div>

          <div className='inputBox'>

            <div>
              <label htmlFor="">Contact</label>
              <input type="text" name="Contact" value={state?.Contact}  onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Address</label>
              <textarea type="text" name="Address"  value={state?.Address}  className='p-2' onChange={stateHandler}  />
            </div>

          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Update" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

        </form>
      </div>
    </div>
  )
}
