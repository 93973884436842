import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function TermCond() {

  const [state, setState] = useState()
  const [Data, setData] = useState([])
  const [submitButton, setSubmitButton] = useState(true)
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const stateHandler = (e)=>{
    setState((prev)=>{
      return {...prev, [e.target.name]:e.target.value}
    })
  }


  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const submitHandler = async(e)=>{
    e.preventDefault()
      try {
        setSubmitButton(false)
        const data = await request("/TermCond/addTermCond", "POST", {
          "Content-Type":"application/json"
        }, {...state, createdBy:user._id})
        setSubmitButton(true)
        if (data.status === 200) {
          successNotifier(data.data?.message)
          fetchTermCond()
        } else {
          errorNotifier(data.data?.message)
        }

      } catch (error) {
        console.log(error)
        setSubmitButton(true)
        errorNotifier("Un-Expected Error")
      }
  }


  const fetchTermCond = async ()=>{
    try {

      const data = await request(`/TermCond/getAllTermCond?createdBy=${user._id}`, "GET")
      setData(data)
      if (data.status !== 200) {
        errorNotifier(data.data?.message)
      }

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }
  useEffect(()=>{
    fetchTermCond()
  },[])

  return (
    <div className='flex flex-col justify-center items-center w-[100%]'>
       <ToastContainer />
        <h1>Terms & Conditions</h1>
        <div className='flex justify-center w-[80%]'>
        <form onSubmit={submitHandler}>
          <div className='inputBox'>

            <div>
              <label htmlFor="">Description</label>
              <textarea type="text" name="description" id="" onChange={stateHandler}/>
            </div>
            <div>
              <label htmlFor="">Select Account</label>
              <select name="accountName" id="" onChange={stateHandler}>
                <option hidden>Chose...</option>
                <option value="Quotations">Quotations</option>
                <option value="Invoices">Invoices</option>
              </select>
            </div>


          </div>

          <div>
          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>
            </div>
        </form>
        </div>

        <table className=" w-3/4 text-sm text-left text-gray-500 dark:text-gray-400 mt-5 mb-20">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                 <th scope="col" className="px-4 py-3">#</th>
                 <th scope="col" className="px-4 py-3">Description</th>
                 <th scope="col" className="px-4 py-3">Account Name</th>
                 <th scope="col" className="px-4 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
            {Data.map((data, index)=>(
              <tr key={data._id} className="border-b dark:border-gray-700">
              <td className="px-4 py-3">{++index}</td>
              <td className="px-4 py-3">{data.description}</td>
              <td className="px-4 py-3">{data.accountName}</td>
              <td className="px-4 py-3">Edit</td>
            </tr>
            ))
              }
            </tbody>
        </table>

    </div>
  )
}
