import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DashBorad from '../DashBorad/DashBorad';
import ClientForm from '../ClientForm/ClientForm';
import ClientList from '../ClientList/ClientList';
import ClientView from '../ClientView/ClientView';
import ClientEditForm from '../ClientEditForm/ClientEditForm';
import ProductForm from '../ProductsForm/ProductForm';
import ProductList from '../ProductList/ProductList';
import ProductsView from '../ProductsView/ProductsView';
import ProductsEditForm from '../ProductsEditForm/ProductsEditForm';
import QuotationForm from '../QuotationForm/QuotationForm';
import QuotationList from '../QuotationList/QuotationList';
import QuotationView from '../QuotationView/QuotationView';
import QuotationPreview from '../QuotationPreview/QuotationPreview';
import QuotationEditForm from '../QuotationEditForm/QuotationEditForm';
import InvoiceForm from '../InvoiceForm/InvoiceForm';
import InvoiceList from '../InvoiceList/InvoiceList';
import InvoiceView from '../InvoiceView/InvoiceView';
import InvoiceEditForm from '../InvoiceEditForm/InvoiceEditForm';
import ReceiptForm from '../ReceiptForm/ReceiptForm';
import ReciptsList from '../ReciptsList/ReciptsList';
import MyProfile from '../MyProfile/MyProfile';
import Settings from '../Settings/Settings';
import UserManager from '../UserManager/UserManager';
import ChangePwd from '../ChangePwd/ChangePwd';
import PageNotFound from '../Page404/Page404';


export default function AppRoutes() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
  <Routes>
  <Route path="/" element={<DashBorad />} />
  <Route path="/CreateClient" element={<ClientForm />} />
  <Route path="/Clients" element={<ClientList />} />
  <Route path="/ClientView/:id" element={<ClientView />} />
  <Route path="/ClientEdit/:id" element={<ClientEditForm />} />
  <Route path="/CreateProduct" element={<ProductForm />} />
  <Route path="/Products" element={<ProductList />} />
  <Route path="/ProductView/:id" element={<ProductsView />} />
  <Route path="/ProductEdit/:id" element={<ProductsEditForm />} />
  <Route path="/CreateQuotation" element={<QuotationForm />} />
  <Route path="/Quotations" element={<QuotationList />} />
  <Route path="/QuotationView/:id" element={<QuotationView />} />
  <Route path="/QuotationEdit/:id" element={<QuotationEditForm />} />
  <Route path="/CreateInvoice" element={<InvoiceForm />} />
  <Route path="/Invoices" element={<InvoiceList />} />
  <Route path="/InvoiceView/:id" element={<InvoiceView />} />
  <Route path="/InvoiceEdit/:id" element={<InvoiceEditForm />} />
  <Route path="/CreatePaymentReceipt" element={<ReceiptForm />} />
  <Route path="/ReciptsList" element={<ReciptsList />} />
  <Route path="/MyProfile" element={<MyProfile />} />
  <Route path="/Settings" element={<Settings />} />
  <Route path="/UserManager" element=
    {user.UserType === "admin" ? <UserManager /> : <PageNotFound />}/>
  <Route path="/changePwd" element={<ChangePwd />} />
  <Route path="*" element={<PageNotFound />} />
</Routes>

    </div>
  )
}
