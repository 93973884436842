import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { request } from '../../util/fetchAPI'
import { useNavigate } from "react-router-dom"
import { FaSearch } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";

export default function QuotationModal({ isQuoteModalOpen, handleOk, handleCancel, selectQuote }) {

  const [quoteData, setQuoteData] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [filterData, setFilteredData] = useState([])
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const fetchProducts = async () => {
    try {

      const data = await request(`/quote/QuoteForInvoice?createdBy=${user._id}`, "GET")
      setQuoteData(data)
      setFilteredData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = (event) => {

    const searchTerm = event.target.value
    setSearchTerm(searchTerm)

    const filterData = quoteData.filter((quote) =>
      quote.QuoteNo.toLowerCase().includes(searchTerm.toLowerCase())
      ||
      quote.ClientId?.ClientName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(filterData)

  }

  useEffect(() => {
    fetchProducts()
  }, [])
  return (
    <Modal
      width={"100%"}
      title="Select Quotation"
      open={isQuoteModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">

              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>

                  <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Search"
                    onChange={handleSearch}
                  />

                </div>
              </div>

            </div>
            <div className="w-screen md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button type="button" className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800" onClick={() => navigate("/CreateQuotation")}>
                <MdAddCircle className='mr-2 text-xl' />
                Add New
              </button>

            </div>

          </div>
          <div className="overflow-x-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">Sr.</th>
                  <th scope="col" className="px-4 py-3">Quote No</th>
                  <th scope="col" className="px-4 py-3">Client</th>
                  <th scope="col" className="px-4 py-3">Subject</th>
                  <th scope="col" className="px-4 py-3">Quote Date</th>
                </tr>
              </thead>
              <tbody>
                {filterData.map((data, index) => (
                  <tr key={data._id}
                    className="hover:bg-indigo-600 hover:text-white cursor-pointer"
                    onClick={() => selectQuote(data)}>
                    <td className="px-4 py-3">{index + 1}</td>
                    <td className="px-4 py-3">{data.QuoteNo}</td>
                    <td className="px-4 py-3">{data.ClientId.ClientName}</td>
                    <td className="px-4 py-3">{data.Subject}</td>
                    <td className="px-4 py-3">{data.QuoteDate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  )
}
