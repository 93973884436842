import React from 'react'

export default function Quotation({clientQuotation}) {
  return (
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">

          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">#</th>
                  <th scope="col" className="px-4 py-3">Quote No</th>
                  <th scope="col" className="px-4 py-3">Subject</th>
                  <th scope="col" className="px-4 py-3">Dated</th>
                  <th scope="col" className="px-4 py-3">Status</th>

                </tr>
              </thead>
              <tbody>

                {clientQuotation.map((data, index) => (
                  <tr key={data._id} className="border-b dark:border-gray-700">
                    <td className="px-4 py-3">{++index}</td>
                    <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.QuoteNo}</th>
                    <td className="px-4 py-3">{data.Subject}</td>
                    <td className="px-4 py-3">{data.QuoteDate}</td>
                    <td className="px-4 py-3">
                      {data.isFinal === false
                        ? <span className='text-red-600 font-semibold'>Pending</span>
                        : <span className='text-lime-600 font-semibold'>Final</span>
                      }
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  )
}
