import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function MyProfileForm() {

  const [state, setState] = useState()
  const [submitButton, setSubmitButton] = useState(true)
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const stateHandler = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }


  const CompanyHandler = async (e) => {
    e.preventDefault()
    try {
      setSubmitButton(false)

      const payload = {
        ...state,
        createdBy:user._id
      }

      const data = await request("/company/registerCompany", "POST", {
        "Content-Type": "application/json"
      }, { ...payload })
      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setTimeout(()=>{
          window.location.reload()
        },[1000])
      } else {
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
    setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>My Profile</h1>
      <div className='formContiner'>
        <form onSubmit={CompanyHandler}>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Company/Firm Name</label>
              <input type="text" name="CompanyName" id="" onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Onwer Name</label>
              <input type="text" name="OnwerName" id="" onChange={stateHandler} required />
            </div>

          </div>

         <div className='inputBox'>

            <div>
              <label htmlFor="">Contact # 1</label>
              <input type="text" name="ContactNo1" id="" onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Contact # 2</label>
              <input type="text" name="ContactNo2" className='p-2' onChange={stateHandler}  />
            </div>

          </div>

          <div className='inputBox'>

            <div>
              <label htmlFor="">Email</label>
              <input type="text" name="Email" id="" onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Address</label>
              <textarea type="text" name="Address" className='p-2' onChange={stateHandler} required />
            </div>

          </div>
          <div className='inputBox'>

            <div>
              <label htmlFor="">NTN</label>
              <input type="text" name="NTN" className='p-2' onChange={stateHandler}  />
            </div>

            <div>
              <label htmlFor="">STN</label>
              <input type="text" name="SNTN" className='p-2' onChange={stateHandler}  />
            </div>

          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

        </form>
      </div>
    </div>
  )
}
