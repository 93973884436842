import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdDelete } from "react-icons/md";
import { Spin } from 'antd';

export default function IdPrefix() {
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)
  const [state, setState] = useState()
  const [prefixData, setPrefixData] = useState([])
  const [selectedRow, setSelectedRow] = useState('')
  const [submitButton, setSubmitButton] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stateHandler = (e)=>{
    setState((prev)=>{
      return {...prev, [e.target.name]:e.target.value}
    })
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }


  const submitHandler = async(e)=>{
    e.preventDefault()
      try {
        setSubmitButton(false)
        const data = await request("/settings/addPrefixId", "POST", {
          "Content-Type":"application/json"
        }, {...state,createdBy:user._id })
        setSubmitButton(true)
        if (data.status === 200) {
          successNotifier(data.data?.message)
          fetchPrefixId()
        } else {
          errorNotifier(data.data?.message)
        }

      } catch (error) {
        console.log(error)
        setSubmitButton(true)
        errorNotifier("Un-Expected Error")
      }
  }


  const fetchPrefixId = async ()=>{
    try {

      const data = await request(`/settings/getAllPrefixId/${user._id}`, "GET")
      setPrefixData(data)
      if (data.status !== 200) {
        errorNotifier(data.data?.message)
      }

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  const deleteHandler = async (id)=>{
    try {

      const data = await request(`/settings/deletePrefix/${id}`, "DELETE")
      handleOk()
      fetchPrefixId()
    if(data.status === 200){
        successNotifier(data.data.message)
      }else{
        errorNotifier(data.data.message)
      }
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(()=>{
      fetchPrefixId()
  },[])

  return (
    <div className='flex flex-col justify-center items-center w-[100%]'>
       <ToastContainer />
        <h1>Prefix & Id Settting</h1>
        <div className='flex justify-center w-[80%]'>
        <form onSubmit={submitHandler}>
          <div className='inputBox'>

            <div>
              <label htmlFor="">Prefix</label>
              <input type="text" name="prefix" id="" onChange={stateHandler}/>
            </div>

            <div>
              <label htmlFor="">Id</label>
              <input type="text" name="Id" id="" onChange={stateHandler}/>
            </div>

          </div>
          <div className='inputBox'>

            <div>
              <label htmlFor="">Select Account</label>
              <select name="accountName" id="" onChange={stateHandler}>
                <option hidden>Chose...</option>
                <option value="Client">Client</option>
                <option value="Products">Products</option>
                <option value="Quotations">Quotations</option>
                <option value="Invoices">Invoices</option>
                <option value="Receipt">Receipt</option>
              </select>
            </div>

          </div>

          <div>
          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>
            </div>
        </form>
        </div>

        <table className=" w-3/4 text-sm text-left text-gray-500 dark:text-gray-400 mt-5 mb-20">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                 <th scope="col" className="px-4 py-3">#</th>
                 <th scope="col" className="px-4 py-3">Prefix</th>
                 <th scope="col" className="px-4 py-3">Id</th>
                 <th scope="col" className="px-4 py-3">Current Id</th>
                 <th scope="col" className="px-4 py-3">Account Name</th>
                 <th scope="col" className="px-4 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
            {prefixData.map((data, index)=>(
              <tr key={data._id} className="border-b dark:border-gray-700">
              <td className="px-4 py-3">{++index}</td>
              <td className="px-4 py-3">{data.prefix}</td>
              <td className="px-4 py-3">{data.Id}</td>
              <td className="px-4 py-3">{data.currentId}</td>
              <td className="px-4 py-3">{data.accountName}</td>
              <td className="px-4 py-3">
                <MdDelete className='text-xl cursor-pointer' onClick={()=>{showModal();
                  setSelectedRow(data._id)}} /></td>
            </tr>
            ))
              }
            </tbody>
        </table>

      <Modal title="Deletion Waring" open={isModalOpen} onOk={() => {
        deleteHandler(selectedRow)
      }} onCancel={handleCancel}>
        <p className='text-red-600 font-semibold'>Would you like to delete this data?</p>
        <p className='text-red-600 font-semibold'>Once the data is deleted, it will not be recovered!</p>
      </Modal>
    </div>
  )
}
