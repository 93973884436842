/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { Modal } from 'antd';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdEditDocument , MdAddCircle, MdPrint } from "react-icons/md";
import { request } from '../../util/fetchAPI'


export default function UserManagerList() {
  const [isLoading, setIsLoading] = useState(true)
  const [userData, setUserData] = useState([])
  const [userStatus, setUserStatus] = useState()
  const [selectedRow, setSelectedRow] = useState('')
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(selectedRow ? true : false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }


  const handleSearch = (event) => {
    setIsLoading(true)
    const searchTerm = event.target.value
    setSearchTerm(searchTerm)
    const filterData = userData.filter((client) =>
      client.ClientId.toLowerCase().includes(searchTerm.toLowerCase())
      ||
      client.ClientName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(filterData)
    setIsLoading(false)
  }

  const fetchUsers = async () => {
    try {
      setIsLoading(true)
      const data = await request("/user/getAllUsers", "GET")
      setUserData(data)
      setFilteredData(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  const userStatusHandler = async(id, status) => {
    try {
      setIsLoading(true)
      const data = await request(`/user/updateStatus/${id}`, "PUT",{
            "Content-Type": "application/json"
      }, {
        isActive:status
      })
      handleOk()
      fetchUsers()
      setIsLoading(false)
      if (data.status === 200) {
        successNotifier(data.data.message)
      } else {
        errorNotifier(data.data.message)
      }
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <div>

    <section className="bg-gray-50 h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
      <ToastContainer />
      <h1 className='flex justify-center'>User List</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">

              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>

                  <input type="text" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Search"
                  onChange={handleSearch}
                  />

                </div>
              </div>

            </div>

            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <button type="button" className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800" onClick={()=>window.scroll(0,0)}>
                <MdAddCircle className='mr-2 text-xl' />
                Add New
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">#</th>
                  <th scope="col" className="px-4 py-3">User Id</th>
                  <th scope="col" className="px-4 py-3">User Type</th>
                  <th scope="col" className="px-4 py-3">Status</th>
                  <th scope="col" className="px-4 py-3">Action</th>
                  <th scope="col" className="px-4 py-3">Detail</th>
                </tr>
              </thead>
              {isLoading ?
              <div className='loaderContainer'>
                  <span className="loader"></span>
              </div>
              :
              <tbody>
              {filteredData.map((data, i) => (
                  <tr key={data._id} className="border-b dark:border-gray-700">
                    <td className="px-4 py-3">{++i}</td>
                    <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.UserId}</th>
                    <td className="px-4 py-3 cursor-pointer">
                      {data.UserType}</td>
                    <td className="px-4 py-3">{data.isActive ?
                    <span className='text-lime-600 font-semibold cursor-pointer'
                     onClick={()=>{userStatusHandler(data._id, false)}}>ACTIVE</span> :
                     <span className='text-red-600 font-semibold cursor-pointer'
                     onClick={()=>{userStatusHandler(data._id, true);}}>IN-ACTIVE</span>}</td>
                    <td className="px-4 py-3 text-indigo-600 font-semibold cursor-pointer">Password</td>
                    <td className="px-4 py-3 text-indigo-600 font-semibold cursor-pointer">Detail</td>
                  </tr>

                ))}
       </tbody>}
            </table>
          </div>
        </div>
      </div>
    </section>

    <Modal title="Deletion Waring" open={isModalOpen} onOk={() => {
        userStatusHandler(selectedRow)
      }} onCancel={handleCancel}>
        <p className='text-red-600 font-semibold'>Would you like to delete this data?</p>
        <p className='text-red-600 font-semibold'>Once the data is deleted, it will not be recovered!</p>
      </Modal>
    </div>
  )
}
