import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import QuotationModal from '../Modals/QuotationModal';
import ClientModal from '../Modals/ClientModal';
import ProductModal from '../Modals/ProductModal';
import TermsCondModal from '../Modals/Terms&CondModal';
import { request } from '../../util/fetchAPI';
import { LoadingOutlined } from '@ant-design/icons';
import { MdAddCircle } from "react-icons/md";
import { Spin } from 'antd';
import "./InvoiceEditForm.css"


export default function InvoiceEditForm() {

  const [invoiceType, setInvoiceType] = useState("Quick")
  const [state, setState] = useState()
  const [submitButton, setSubmitButton] = useState(true)
  const [selectedClient, setSelectedClient] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState()
  const [quoteProducts, setQuoteProducts] = useState([])
  const [productRows, setProductsRows] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isTermCondModalOpen, setIsTermCondModalOpen] = useState(false);
  const [termCond, setTermCond] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const { id } = useParams();

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const handleRowInputChange = (index, field, value) => {
    const updatedRows = [...productRows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value,
    };
    setProductsRows(updatedRows);
  };

  const showQuoteModal = () => {
    setIsQuoteModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showProductModal = (index) => {
    setIsProductModalOpen(true)
    setSelectedRowIndex(index);
  };

  const showTermCondModal = (index) => {
    setIsTermCondModalOpen(true)
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsProductModalOpen(false)
    setIsQuoteModalOpen(false)
    setIsTermCondModalOpen(false)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsProductModalOpen(false)
    setIsQuoteModalOpen(false)
    setIsTermCondModalOpen(false)
  };

  const selectQuote = async (Quote) => {
    const QuoteData = {
      _id: Quote._id,
      QuoteNo: Quote.QuoteNo,
      QuoteDate: Quote.QuoteDate,
      ClientId: Quote.ClientId?._id,
      ClientCode: Quote.ClientId?.ClientId,
      ClientName: Quote.ClientId?.ClientName

    }
    const productData = await request(`/quote/QuoteProducts/${QuoteData._id}`, "GET")
    setQuoteProducts(productData)
    setSelectedQuote(QuoteData)
    handleCancel()
  }

  const selectClient = (client) => {

    const data = {
      _id: client._id,
      ClientId: client.ClientId,
      ClientName: client.ClientName,
    }

    setSelectedClient(data)
    handleCancel()
  }


  const productSelect = (product) => {
    const newProducts = [...productRows];

    newProducts[selectedRowIndex] = {
      ...newProducts[selectedRowIndex],
      _id: product._id,
      ProductCode: product.ProductCode,
      ProductName: product.ProductName,
      ProductUnit: product.ProductUnit,
      Specs: product.Specs,
      rate: product.rate,
      tax: product.tax
    };

    setProductsRows(newProducts);
    handleCancel(); // Close the modal
  };


  const handleRowClick = (id) => {
    setSelectedRowId(selectedRowId === id ? null : id);
  };


  const rowInsertHandler = () => {
    setProductsRows(
      [
        ...productRows,
        {
          id: productRows.length + 1,
          ProductCode: "",
          ProductName: "",
          ProductUnit: "",
          Specs: "",
          qty: "",
          rate: "",
          tax: "",
        }
      ]
    )
  }

  const rowDeleteHandler = (id) => {
    const deletRow = productRows.filter((row) => row.id !== id)
    setProductsRows(deletRow)
  }

  const amountCalcu = (index, field, value) => {
    const updatedRows = ([...productRows]);
    let qty = parseFloat(updatedRows[index].qty) || 0;
    let rate = parseFloat(updatedRows[index].rate) || 0;
    let tax = parseFloat(updatedRows[index].tax) || 0;
    let taxRate = parseFloat(updatedRows[index].taxRate) || 0;

    // Update the specific field
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value
    };

    // Recalculate the tax and amount
    if (field === "rate" || field === "qty" || field === "tax") {
      rate = parseFloat(updatedRows[index].rate) || 0;
      qty = parseFloat(updatedRows[index].qty)  || 0;
      tax = parseFloat(updatedRows[index].tax)  || 0;
      taxRate = Math.round( rate * (tax / 100)) || 0;  // Assuming tax is a percentage

      updatedRows[index].taxRate = taxRate;
      updatedRows[index].amount = qty * (rate + taxRate);
    }

    setProductsRows(updatedRows);
  };

  const calculateGrandTotal = () => {
    const rows = productRows || quoteProducts || [];
    const total = rows.reduce((sum, row) => {
      const amount = parseFloat(row.amount || (((row.rate * (row.tax / 100)) + row.rate) * row.qty)) || 0;
      return sum + amount;
    }, 0);
    setGrandTotal(total);
  };

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return 0;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,  // Allow decimal formatting if needed
    });
    return formatter.format(number);
  };


  const selectTermCond = (isChecked, NewTermCond) => {
    if (isChecked) {
      // Add the selected term condition
      setTermCond((prevTermCond) => [
        ...prevTermCond,
        {
          _id: NewTermCond._id,
          description: NewTermCond.description,
        },
      ]);
    } else {
      // Remove the unselected term condition
      setTermCond((prevTermCond) =>
        prevTermCond.filter((term) => term._id !== NewTermCond._id)
      );
    }
  };

  const InvoiceUpdateHandler = async (e) => {
    e.preventDefault();
    setSubmitButton(false)

    try {
      const productData = (invoiceType === "Quick" ? productRows : quoteProducts).map(({ _id, ProductId, Specs, qty, rate, tax }) => ({
        _id,
        ProductId,
        Specs,
        qty,
        rate,
        tax
      }));

      let termConId = []
      termConId = termCond && termCond.map(({ _id }) => ({
        _id,
      }))

      const payload = {
        ...state,
        QuoteId:selectedQuote._id,
        ClientId: selectedQuote ?
        selectedQuote.ClientId : selectedClient._id,
        productData,
        termCond: termConId
      };

      const data = await request("/invoice/updateInvoice", "PUT", { "Content-Type": "application/json" }, {
        ...payload,
      });

      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setQuoteProducts([])
        setSelectedQuote([])
        setProductsRows([])
        setSelectedRowId(null)
        setSelectedRowIndex(null)
        setTermCond(null)
        setSelectedClient(null)

      } else {
        errorNotifier(data.data.message)
      }


    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  };

  const fetchInvoice = async (id) => {
    try {
      const data = await request(`/invoice/getOnlyInvBy/${id}`, "GET")
      setState(data.InvData)
      setInvoiceType(data.QuoteData._id ? "Quote" : "Quick")
      setSelectedQuote(data.QuoteData._id ? data.QuoteData : "")
      setQuoteProducts(data.QuoteData._id ? data.InvoiceProduct : "")
      setProductsRows(!data.QuoteData._id ? data.InvoiceProduct : "")
      setSelectedClient(!data.QuoteData._id ? data.InvData?.ClientId : "")
      setTermCond(data.InvData?.termCond)

    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }


  useEffect(() => {
    fetchInvoice(id)
  }, [id])

  useEffect(() => {
    calculateGrandTotal();
  }, [productRows, quoteProducts]);


  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Update Invoice</h1>
      <div className='formContiner'>
        <form onSubmit={InvoiceUpdateHandler}>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Invoice No.#</label>
              <input type="text" name="InvoiceNo"
                value={state?.InvoiceNo} required onChange={stateHandler} />
            </div>
            <div>
              <label htmlFor="">Date</label>
              <input type="date" name="InvoiceDate" value={state?.InvoiceDate} required onChange={stateHandler} />
            </div>

          </div>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Invoice Type</label>
              <input type="text"
                  value={invoiceType}
                  readOnly />
            </div>

            {invoiceType === "Quote" &&
              <div>
                <label htmlFor="">Select Quotation</label>
                <input type="text" name="" id=""
                  value={selectedQuote ? selectedQuote.QuoteNo + " - " + selectedQuote.QuoteDate : ""} onClick={() => showQuoteModal()}
                  readOnly required />
              </div>
            }

          </div>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Client Id</label>
              <input type="text" name="" id=""
              value={selectedQuote ?
              selectedQuote.ClientCode
              :
              selectedClient?.ClientId}
                required />
            </div>

            <div>
              <label htmlFor="">Client Name</label>
              <input type="text" name="" id=""
              value={
              selectedQuote ?
              selectedQuote.ClientName
              :
              selectedClient?.ClientName}
                required />
            </div>


          </div>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Subject</label>
              <textarea type="text" name="Subject" className='p-2' value={state?.Subject} required onChange={stateHandler} />
            </div>

            {invoiceType === "Quote" &&
              <div>
                <label htmlFor="">Ref/Purchase Order</label>
                <input type="text" name="refPO" className='p-2' value={state?.refPO} required onChange={stateHandler} />
              </div>
            }
          </div>


          {invoiceType === "Quick" &&
            <div className='Buttons'>
              <span onClick={() => rowInsertHandler()}>Add</span>
              <span onClick={() => { rowDeleteHandler(selectedRowId) }}>Delete</span>
            </div>
          }

          <div className="overflow-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">#</th>
                  <th scope="col" className="px-4 py-3">Pr. Code</th>
                  <th scope="col" className="px-4 py-3">Product</th>
                  <th scope="col" className="px-4 py-3">Specs</th>
                  <th scope="col" className="px-4 py-3">Unit</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Qty</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Rate</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Tax%</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Tax</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Amount</th>
                </tr>
              </thead>
              <tbody>

                {invoiceType === "Quote" &&
                  quoteProducts && quoteProducts.map((data, index) => (
                    <tr key={index} className={
                      selectedRowId === index || data.id
                        ? "bg-indigo-800 text-white"
                        : "text-black"
                    }
                      onClick={() => handleRowClick(data.id || index)}>

                      <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1}</td>

                      <th scope="row" className="px-2 py-2 w-44 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400">{data.ProductId?.ProductCode || data.ProductCode}</th>

                      <td className="w-96 px-2 py-2 border-2 border-gray-400">{data.ProductId?.ProductName || data.ProductName}</td>

                      <td className="w-52 px-2 py-2 border-2 border-gray-400"><input type="text" className='w-52 p-2 rounded-md text-black text-sm' name="rate" value={data.Specs} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.ProductId?.ProductUnit || data.ProductUnit}</td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">{data.qty}</td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.rate)}.00</td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">
                        {data.tax}%
                      </td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.taxRate ? data.taxRate : (data.rate * (data.tax / 100)))}.00</td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.amount ? data.amount : (((data.rate * (data.tax / 100)) + data.rate) * data.qty)) || 0}.00</td>
                    </tr>
                  ))}

                 {invoiceType === "Quick" &&
                  productRows && productRows.map((data, index) => (
                    <tr key={index} className={
                      selectedRowId === data.id
                        ? "bg-indigo-800 text-white"
                        : "text-black"
                    }
                      onClick={() => handleRowClick(data.id)}>

                      <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1}</td>
                      <th scope="row" className="px-2 py-2 w-44 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400" onDoubleClick={() => showProductModal(index)}>{data.ProductCode}</th>

                      <td className="w-96 px-2 py-2 border-2 border-gray-400"
                       onDoubleClick={() => showProductModal(index)}>{data.ProductName}</td>

                      <td className="w-52 px-2 py-2 border-2 border-gray-400">{data.Specs}</td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.ProductUnit}</td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16">
                        <input type="Number" className='w-16 p-2 rounded-md text-black' name="qty" value={data.qty}
                         onChange={(e) => amountCalcu(index, "qty", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-28"><input type="Number" className='w-28 p-2 rounded-md text-black' name="rate" value={data.rate} onChange={(e) => amountCalcu(index, "rate", e.target.value)} /></td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">
                      <input
                        type="Number"
                        className='w-28 p-2 rounded-md text-black'
                        name="tax"
                        value={data.tax || ""}
                        onChange={(e) => amountCalcu(index, "tax", e.target.value)}
                      />
                    </td>

                    <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.taxRate ? data.taxRate : (data.rate * (data.tax / 100)))}.00</td>

                    <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.amount ? data.amount :  (((data.rate * (data.tax / 100))+ data.rate) * data.qty)) || 0}.00</td>


                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end mt-4">
            <h3 className="text-xl font-semibold">Grand Total: {formatNumberToInternational(grandTotal)}.00</h3>
          </div>

          <div className='termCond'>
            <h1 className="flex text-2xl gap-2 cursor-pointer"> <span>Terms & Condition </span><MdAddCircle onClick={showTermCondModal} /></h1>
            <ul style={{ listStyle: "none" }}>
              {termCond && termCond.map((data, index) => (
                <li>{++index} - {data.description}</li>))}
            </ul>
          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Update" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />} /> </>

          }</button>
        </form>
      </div>

      <QuotationModal
        isQuoteModalOpen={isQuoteModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectQuote={selectQuote}
      />
      <ClientModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectClient={selectClient}
      />

      <ProductModal
        isProductModalOpen={isProductModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        productSelect={productSelect}
      />

      <TermsCondModal
        isTermCondModalOpen={isTermCondModalOpen}
        accountName={"Invoices"}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectTermCond={selectTermCond}
      />

    </div>
  )
}
