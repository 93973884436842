const BASE_URL = "https://api.quotemanager.online"


export const request = async (url, method, headers = {}, body = {}, isNotStringified = false) => {
    let res
    let data
    let status
    switch (method) {
        case 'GET':
            res = await fetch(BASE_URL + url, { headers })
            if(res.status !== 200 && res.status !== 201) throw new Error("ERROR")
            data = await res.json()
            return data

        case 'POST':

            if (isNotStringified) {
                res = await fetch(BASE_URL + url,  { headers, method, body })
                data = await res.json()

            } else {
                    res = await fetch(BASE_URL+url, { headers, method, body: JSON.stringify({ ...body }) })
                    data = await res.json()
                    status = res.status;


            }

            return {data,status}


        case 'PUT':
            res = await fetch(BASE_URL + url, { headers, method, body: JSON.stringify(body) })
            if(res.status !== 200 && res.status !== 201) throw new Error("ERROR")
            status = res.status;
            data = await res.json()
            return {data,status}


        case 'DELETE':
            res = await fetch(BASE_URL + url, { headers, method })
            data = await res.json()
            status = res.status;
            return {data,status}
        default:
            return
    }
}