/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


export default function ProductsEditForm() {

  const [state, setState] = useState()
  const [submitButton, setSubmitButton] = useState(true)
  const {id} = useParams()

    const stateHandler = (e)=>{
        setState((prev)=>{
            return {...prev, [e.target.name] : e.target.value}
        })
    }

    const successNotifier = (data) => {
      toast.success(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }

    const errorNotifier = (data) => {
      toast.error(data, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }


    const ProductUpdateHandler = async (e) => {
      e.preventDefault()
      try {
        setSubmitButton(false)
        const data = await request("/product/updateProduct", "PUT", {
          "Content-Type": "application/json"
        }, { ...state } )
        setSubmitButton(true)
        if (data.status === 200) {
          setState(data)
          successNotifier("Data Updated")
        } else {
          errorNotifier(data.data.message)
        }

      } catch (error) {
        console.log(error)
      setSubmitButton(true)
        errorNotifier("Un-Expected Error")
      }
    }

    const fetchProduct = async (id) => {
      try {
        const data = await request(`/product/getOnlyProductBy/${id}`, "GET")
        setState(data)
        if (data.status !== 200) {
          errorNotifier(data.data?.message)
        }

      } catch (error) {
        console.log(error)
        errorNotifier("Un-Expected Error")
      }
    }

    useEffect(()=>{
      fetchProduct(id)
    },[id])
  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Update Product</h1>
      <div className='formContiner'>
        <form onSubmit={ProductUpdateHandler}>
        <div className='inputBox'>
          <div>
          <label htmlFor="">Product Code</label>
          <input type="text" name="ProductCode" value={state?.ProductCode} onChange={stateHandler}/>
          </div>
          <div>
          <label htmlFor="">Product Name</label>
          <input type="text" name="ProductName" value={state?.ProductName} onChange={stateHandler} required/>
          </div>

          </div>

          <div className='inputBox'>
          <div>
            <label htmlFor="">Unit</label>
            <input type="text" name="ProductUnit"
             value={state?.ProductUnit} onChange={stateHandler} required/>
            </div>

            <div>
            <label htmlFor="">Specs</label>
            <textarea type="text" name="Specs"
             value={state?.Specs} className='p-2' onChange={stateHandler} />
            </div>

            </div>

            <div className='inputBox'>
            <div>
            <label htmlFor="">Rate</label>
            <input type="Number" min={1} name="rate"  value={state?.rate} onChange={stateHandler} required/>
            </div>
            </div>

            <button className='SubButton' disabled={!submitButton}>{submitButton ? "Update" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

        </form>
      </div>
    </div>
  )
}
