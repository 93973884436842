import React, { useEffect, useState } from 'react'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { request } from '../../util/fetchAPI'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export default function MyProfileView({isCompanyExit}) {

  const [state, setState] = useState(isCompanyExit)
  const [submitButton, setSubmitButton] = useState(true)
  const [submitButton2, setSubmitButton2] = useState(true)

  const stateHandler = (e) => {
    setState((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }


const updateCompany = async (e) => {
    e.preventDefault()
  try {
       setSubmitButton(false)
      const data = await request("/company/updateCompany", "PUT", {
        "Content-Type": "application/json"
      }, { ...state })
      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier("Company Data Updated")
        setState(data)
      } else {
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  }

const deleteCompany = async (id) => {
  try {
      setSubmitButton2(false)
      const data = await request(`/company/deleteCompany/${id}`, "DELETE")
      setSubmitButton2(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setTimeout(()=>{
          window.location.reload()
        },[1000])

      } else {
        setSubmitButton2(true)
        errorNotifier(data.data.message)
      }

    } catch (error) {
      console.log(error)
     setSubmitButton2(true)
      errorNotifier("Un-Expected Error")
    }
  }


  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>My Profile</h1>
      <div className='formContiner'>
        <form onSubmit={updateCompany}>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Company/Firm Name</label>
              <input type="text" name="CompanyName"
              value={state.CompanyName} onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Onwer Name</label>
              <input type="text" name="OnwerName" value={state.OnwerName} onChange={stateHandler} required />
            </div>

          </div>

         <div className='inputBox'>

            <div>
              <label htmlFor="">Contact # 1</label>
              <input type="text" name="ContactNo1" value={state.ContactNo1} onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Contact # 2</label>
              <input type="text" name="ContactNo2" className='p-2'
              value={state.ContactNo2} onChange={stateHandler} required />
            </div>

          </div>

          <div className='inputBox'>

            <div>
              <label htmlFor="">Email</label>
              <input type="text" name="Email" value={state.Email} onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">Address</label>
              <textarea type="text" name="Address" className='p-2' value={state.Address} onChange={stateHandler} required />
            </div>

          </div>
          <div className='inputBox'>

            <div>
              <label htmlFor="">NTN</label>
              <input type="text" name="NTN" className='p-2' value={state.NTN} onChange={stateHandler} required />
            </div>

            <div>
              <label htmlFor="">STN</label>
              <input type="text" name="SNTN" className='p-2' value={state.SNTN} onChange={stateHandler} required />
            </div>

          </div>

        <div className='flex gap-5'>
        <button className='SubButton' disabled={!submitButton}>{submitButton ? "Update" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>

          <button type='button' className='delButton' onClick={()=>deleteCompany(state._id)} disabled={!submitButton}>{submitButton2 ? "Delete" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />}/> </>
          }</button>
        </div>


        </form>
      </div>
    </div>
  )
}
