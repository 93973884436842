import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './QuotationPreview.css';

const QuotationPreview = ({ isPrevModalOpen, handleOk, handleCancel, data }) => {
  const [quoteFormat, setQuoteFormat] = useState();
  const [quoteData, setQuoteData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const renderImage = (src, alt, style) => (
    <img
      src={src}
      alt={alt}
      style={style}
    />
  );

  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const calculateGrandTotal = () => {
    return productData.reduce((acc, data) => {
      const taxRate = data.tax || 0; // Default to 0 if tax is not provided
      const totalForItem = (data.rate * (1 + taxRate / 100)) * data.qty;
      return acc + totalForItem;
    }, 0);
  };


  const handleDownloadPDF = () => {
    const input = componentRef.current;
    setIsLoading(true);
    html2canvas(input, {
      scale: 4,
      useCORS: true
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
        compress: true,
        precision: 16
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      setIsLoading(false);
      pdf.save('quotation.pdf');
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const Quotation = React.forwardRef(({ quoteFormat, productData, quoteData }, ref) => (
    <div ref={ref} className='quotation-container'>
      {quoteFormat?.Header && renderImage(
        `https://api.quotemanager.online/images/${quoteFormat.Header}`,
        "Header",
        { width: '100%', height: "18vh", marginBottom: '15px' }
      )}

      <div className='quotation-content'>
        {quoteData?.map((quote, index) => (
          <div key={index} className='quotation-header'>
            <div className='client-info'>
              <h1>Ref # {quote?.QuoteNo}</h1>
              <div className='mb-3 mt-3'>
                <p>To Attention : </p>
                <p className='text-black font-semibold'> {quote?.ClientId?.ContactPerson || 'N/A'}</p>
                <p> {quote?.ClientId?.ClientName || 'N/A'}</p>
                <p> {(quote?.ClientId?.Contact + " , " + quote?.ClientId?.Email) || 'N/A'}</p>
                <p> {quote?.ClientId?.Address || 'N/A'}</p>
              </div>

              <p className='text-xl font-semibold'>Subject: {quote?.Subject || 'N/A'}</p>
            </div>
            <div className='date-info'>
              <p>Date: {quote?.QuoteDate || 'N/A'}</p>
            </div>
          </div>
        ))}

        <table className='quotation-table'>
          <thead>
            <tr>
              <th className='w-8'>#</th>
              <th>Product</th>
              <th className='w-14'>Unit</th>
              <th className='w-14'>Quantity</th>
              <th className='w-24'>Rate</th>
              <th className='w-24'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {productData?.map((product, index) => {
              const taxRate = product.tax || 0; // Default to 0 if tax is not provided
              const taxAmount = product.rate * (taxRate / 100);
              const totalAmount = (product.rate + taxAmount) * product.qty;

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td className='text-start'>
                    <div className='product-name text-start'>{product.ProductName}</div>
                    <div className='product-specs'>{product.Specs}</div>
                  </td>
                  <td>{product.ProductUnit}</td>
                  <td>{product.qty}</td>
                  <td>{formatNumberToInternational(product.rate + taxAmount)}.00</td>
                  <td className='amount font-semibold'>{formatNumberToInternational(totalAmount)}.00</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className='total-amount'>
          Grand Total: {formatNumberToInternational(calculateGrandTotal())}.00
        </div>
      </div>

      <div className='mt-10'>
        <h3 className='text-lg font-semibold'>Terms & Conditions : </h3>
        {quoteData.map((data, index) => (
          <p className='text-sm mt-2' key={index}>{index + 1} - {data.termCond?.description}</p>
        ))}
      </div>

      {quoteFormat?.Stamp && renderImage(
        `https://api.quotemanager.online/images/${quoteFormat.Stamp}`,
        "Stamp",
        { width: '25%', margin: '10% 0 10% 5%' }
      )}

      {quoteFormat?.Footer && (
        <div className='footer'>
          {renderImage(
            `https://api.quotemanager.online/images/${quoteFormat.Footer}`,
            "Footer",
            { width: '100%' }
          )}
        </div>
      )}
    </div>
  ));

  useEffect(() => {
    setQuoteFormat(data?.FormateData);
    setQuoteData(data?.collectQuoteData || []);
    setProductData(data?.products || []);
  }, [data]);

  return (
    <Modal
      title="Preview Quotation"
      centered
      open={isPrevModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
    >
      <div className="quotation">
        <button className='btn-print' onClick={handlePrint}>Print Document</button>
        <button className='btn-download' onClick={handleDownloadPDF} disabled={isLoading}>
          {isLoading ? "Loading..." : "Download PDF"}
        </button>
        <hr className='separator' />
        <Quotation ref={componentRef} quoteFormat={quoteFormat} productData={productData} quoteData={quoteData} />
      </div>
    </Modal>
  );
};

export default QuotationPreview;