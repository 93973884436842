import React, { useState } from 'react'
import IdPrefix from '../IdPrefix/IdPrefix'
import FormateSetting from '../FormateSetting/FormateSetting'
import TermCond from '../TermCond/TermCond'
import "./Settings.css"

export default function Settings() {

  const [modelKey, setModelKey] = useState()

  return (
    <div>
      <div className='w-[100%] flex justify-center'>
        <h1>Settings</h1>
      </div>

      <div className='settingContainer'>
        <ul className='p-5 cursor-pointer flex flex-col gap-2'>
          <li onClick={() => { setModelKey(1) }}  className='hover:text-indigo-500'>1 - Prefix & Id Setting</li>
          <li onClick={() => { setModelKey(2) }}  className='hover:text-indigo-500'>2 - Document Format Setting</li>
          <li onClick={() => { setModelKey(4) }}  className='hover:text-indigo-500'>4 - Terms & Conditions</li>
        </ul>
      </div>
      <div className='flex justify-center items-center p-3'>
        {modelKey === 1 &&
          <IdPrefix />
        }

        {modelKey === 2 &&
          <FormateSetting />
        }


         {modelKey === 4 &&
          <TermCond />
        }
      </div>


    </div>

  )
}
