import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import QuotationModal from '../Modals/QuotationModal';
import ClientModal from '../Modals/ClientModal';
import ProductModal from '../Modals/ProductModal';
import TermsCondModal from '../Modals/Terms&CondModal';
import { request } from '../../util/fetchAPI';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import { MdAddCircle } from "react-icons/md";
import { Spin } from 'antd'
import "./InvoiceForm.css"


export default function InvoiceForm() {

  const [invoiceType, setInvoiceType] = useState("Quick")
  const [state, setState] = useState()
  const [prefix, setPrefix] = useState({ prefix: "", Id: "", currentId: "" })
  const [submitButton, setSubmitButton] = useState(true)
  const [isTermCondModalOpen, setIsTermCondModalOpen] = useState(false);
  const [termCond, setTermCond] = useState([]);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState()
  const [quoteProducts, setQuoteProducts] = useState([])
  const [productRows, setProductsRows] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [grandTotal, setGrandTotal] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const {token} = useSelector((state)=>state.auth)

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const successNotifier = (data) => {
    toast.success(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const errorNotifier = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }

  const showQuoteModal = () => {
    setIsQuoteModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showProductModal = (index) => {
    setIsProductModalOpen(true)
    setSelectedRowIndex(index);
  };

  const showTermCondModal = (index) => {
    setIsTermCondModalOpen(true)
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsProductModalOpen(false)
    setIsQuoteModalOpen(false)
    setIsTermCondModalOpen(false)
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsProductModalOpen(false)
    setIsQuoteModalOpen(false)
    setIsTermCondModalOpen(false)
  };

  const selectQuote = async (Quote) => {
    console.log(Quote)
    const QuoteData = {
      _id: Quote._id,
      QuoteNo: Quote.QuoteNo,
      QuoteDate: Quote.QuoteDate,
      ClientId: Quote.ClientId?._id,
      ClientCode: Quote.ClientId?.ClientId,
      ClientName: Quote.ClientId?.ClientName

    }
    const productData = await request(`/quote/QuoteProducts/${QuoteData._id}`, "GET")
    setQuoteProducts(productData)
    setSelectedQuote(QuoteData)
    handleCancel()
  }

  const selectClient = (client) => {

    const data = {
      _id: client._id,
      ClientId: client.ClientId,
      ClientName: client.ClientName,
    }

    setSelectedClient(data)
    handleCancel()
  }


  const productSelect = (product) => {
    const newProducts = [...productRows];

    newProducts[selectedRowIndex] = {
      ...newProducts[selectedRowIndex],
      _id: product._id,
      ProductCode: product.ProductCode,
      ProductName: product.ProductName,
      ProductUnit: product.ProductUnit,
      Specs: product.Specs,
      rate: product.rate,
      tax: product.tax
    };

    setProductsRows(newProducts);
    handleCancel(); // Close the modal
  };


  const selectTermCond = (isChecked, NewTermCond) => {
    if (isChecked) {
      // Add the selected term condition
      setTermCond((prevTermCond) => [
        ...prevTermCond,
        {
          _id: NewTermCond._id,
          description: NewTermCond.description,
        },
      ]);
    } else {
      // Remove the unselected term condition
      setTermCond((prevTermCond) =>
        prevTermCond.filter((term) => term._id !== NewTermCond._id)
      );
    }
  };


  const handleRowClick = (id) => {
    setSelectedRowId(selectedRowId === id ? null : id);
  };


  const rowInsertHandler = () => {
    setProductsRows(
      [
        ...productRows,
        {
          id: productRows.length + 1,
          ProductCode: "",
          ProductName: "",
          ProductUnit: "",
          Specs: "",
          qty: "",
          rate: "",
          tax:"",
        }
      ]
    )
  }

  const rowDeleteHandler = (id) => {
    const row = productRows || quoteProducts || []
    const deletRow = row.filter((row) => (row.id || row._id) !== id)
    setQuoteProducts(deletRow)
    setProductsRows(deletRow)
  }
  const amountCalcu = (index, field, value) => {
    const updatedRows = ([...productRows]);
    console.log(updatedRows)
    let qty = parseFloat(updatedRows[index].qty) || 0;
    let rate = parseFloat(updatedRows[index].rate) || 0;
    let tax = parseFloat(updatedRows[index].tax) || 0;
    let taxRate = parseFloat(updatedRows[index].taxRate) || 0;

    // Update the specific field
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value
    };

    // Recalculate the tax and amount
    if (field === "rate" || field === "qty" || field === "tax") {
      rate = parseFloat(updatedRows[index].rate) || 0;
      qty = parseFloat(updatedRows[index].qty) || 0;
      tax = parseFloat(updatedRows[index].tax) || 0;
      taxRate = Math.round( rate * (tax / 100));  // Assuming tax is a percentage

      updatedRows[index].taxRate = taxRate;
      updatedRows[index].amount = qty * (rate + taxRate);
    }

    setProductsRows(updatedRows);
  };

  const quoteAmountCalcu = (index, field, value) => {
    const updatedRows = ([...quoteProducts]);
    let qty = parseFloat(updatedRows[index].qty) || 0;
    let rate = parseFloat(updatedRows[index].rate) || 0;
    let tax = parseFloat(updatedRows[index].tax) || 0;
    let taxRate = parseFloat(updatedRows[index].taxRate) || 0;

    // Update the specific field
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value
    };

    // Recalculate the tax and amount
    if (field === "rate" || field === "qty" || field === "tax") {
      rate = parseFloat(updatedRows[index].rate) || 0;
      qty = parseFloat(updatedRows[index].qty) || 0;
      tax = parseFloat(updatedRows[index].tax) || 0;
      taxRate = Math.round( rate * (tax / 100));  // Assuming tax is a percentage

      updatedRows[index].taxRate = taxRate;
      updatedRows[index].amount = qty * (rate + taxRate);
    }

    setQuoteProducts(updatedRows);
  };

  const calculateGrandTotal = () => {
    const rows = productRows || quoteProducts || [];
    const total = rows.reduce((sum, row) => {
      const amount = parseFloat(row.amount || (((row.rate * (row.tax / 100)) + row.rate) * row.qty)) || 0;
      return sum + amount;
    }, 0);
    setGrandTotal(total);
  };


  const formatNumberToInternational = (number) => {
    if (isNaN(number)) return number;
    const formatter = new Intl.NumberFormat("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  const fetchPrerfixData = async () => {
    try {

      const data = await request(`/settings/getPrefixIdBy?accountName=${"Invoices"}&createdBy=${user._id}`, "GET")
      setPrefix(data)
    } catch (error) {
      console.log(error)
      errorNotifier("Un-Expected Error")
    }
  }

  const InvoiceHandler = async (e) => {
    e.preventDefault();
    setSubmitButton(false)

    try {
      const productData = (invoiceType === "Quick" ? productRows : quoteProducts).map(({ _id, ProductId, Specs, qty, rate,tax }) => ({
        _id,
        ProductId,
        Specs,
        qty,
        rate,
        tax
      }));

      let termConId = []
      termConId = termCond && termCond.map(({ _id }) => ({
        _id,
      }))

      const payload = {
        ...state,
        createdBy:user._id,
        InvoiceNo: prefix?.prefix
          + "-" + prefix?.currentId,
        QuoteId: selectedQuote ? selectedQuote._id : null,
        ClientId: selectedQuote ? selectedQuote.ClientId : selectedClient._id,
        productData,
        invoiceType: invoiceType,
        termCond: termConId
      };

      const data = await request("/invoice/createInvoice", "POST", { "Content-Type": "application/json" }, {
        ...payload,
      });

      setSubmitButton(true)
      if (data.status === 200) {
        successNotifier(data.data.message)
        setQuoteProducts([])
        setSelectedQuote([])
        setProductsRows([])
        setSelectedRowId(null)
        setSelectedRowIndex(null)
        setTermCond(null)
        setSelectedClient(null)
        fetchPrerfixData()
      } else {
        errorNotifier(data.data.message)
      }


    } catch (error) {
      console.log(error)
      setSubmitButton(true)
      errorNotifier("Un-Expected Error")
    }
  };

  useEffect(() => {
    fetchPrerfixData()
  }, [])

  useEffect(() => {

    calculateGrandTotal()

  }, [productRows, quoteProducts]);

  return (
    <div className='QuoteContainer'>
      <ToastContainer />
      <h1>Create Invoice</h1>
      <div className='formContiner'>
        <form onSubmit={InvoiceHandler}>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Invoice No.#</label>
              <input type="text" name="QuoteNo" value={prefix ? (prefix?.prefix
                + " - "+prefix?.currentId) : ""} required disabled/>
            </div>
            <div>
              <label htmlFor="">Date</label>
              <input type="date" name="InvoiceDate" id="" required onChange={stateHandler} />
            </div>

          </div>

          <div className='inputBox'>
            <div>
              <label htmlFor="">Invoice Type</label>
              <select name="" id="" required onChange={(e) => { setInvoiceType(e.target.value); setProductsRows(""); setSelectedQuote(""); setSelectedClient(""); setQuoteProducts('') }}>
                <option value="Quick" defaultValue>Quick Invoice</option>
                <option value="Quote">Link Quotation</option>
              </select>
            </div>

            {invoiceType === "Quote" &&
              <div>
                <label htmlFor="">Select Quotation</label>
                <input type="text" name="" id="" value={selectedQuote ? selectedQuote.QuoteNo + " - " + selectedQuote.QuoteDate : ""} onClick={() => showQuoteModal()}
                  readOnly required />
              </div>
            }

          </div>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Client Id</label>
              <input type="text" name="" id=""
                value={
                  selectedClient  ?  selectedClient.ClientId   :
                  selectedQuote   ?  selectedQuote?.ClientCode: " "
                }
                onClick={() => {
                  if (invoiceType === "Quick") { showModal() }

                }} readOnly required />

            </div>

            <div>
              <label htmlFor="">Client Name
              </label>
              <input type="text" name="" id=""
                value={
                  selectedClient ? selectedClient?.ClientName :
                  selectedQuote  ? selectedQuote?.ClientName  : ""
                } readOnly />

            </div>
          </div>
          <div className='inputBox'>
            <div>
              <label htmlFor="">Subject</label>
              <textarea type="text" name="Subject" className='p-2' required onChange={stateHandler} />
            </div>

            {invoiceType === "Quote" &&
              <div>
                <label htmlFor="">Ref/Purchase Order</label>
                <input type="text" name="refPO" className='p-2' required onChange={stateHandler} />
              </div>
            }
          </div>


            <div className='Buttons'>

          {invoiceType === "Quick" &&
              <span onClick={() => rowInsertHandler()}>Add</span>}
              <span onClick={() => { rowDeleteHandler(selectedRowId) }}>Delete</span>
            </div>

          <div className="overflow-auto">
            <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-4 py-3">#</th>
                  <th scope="col" className="px-4 py-3">Pr. Code</th>
                  <th scope="col" className="px-4 py-3">Product</th>
                  <th scope="col" className="px-4 py-3">Specs</th>
                  <th scope="col" className="px-4 py-3">Unit</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Qty</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Rate</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Tax%</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Tax</th>
                  <th scope="col" className="px-4 py-3 overflow-hidden">Amount</th>
                </tr>
              </thead>
              <tbody>

                {invoiceType === "Quote" &&
                  quoteProducts && quoteProducts.map((data, index) => (
                    <tr key={data._id} className={
                      selectedRowId === data._id
                        ? "bg-indigo-800 text-white"
                        : "text-black"
                    }
                      onClick={() => handleRowClick(data._id)}>

                      <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1}</td>

                      <th scope="row" className="px-2 py-2 w-44 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400">{data.ProductId?.ProductCode}</th>
                      <td className="w-96 px-2 py-2 border-2 border-gray-400">{data.ProductId?.ProductName}</td>

                      <td className="w-52 px-2 py-2 border-2 border-gray-400"><input type="text" className='w-52 p-2 rounded-md text-black text-sm' name="rate" value={data.Specs} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.ProductId?.ProductUnit}</td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16"><input type="Number" className='w-16 p-2 rounded-md text-black' value={data.qty} name="qty" onChange={(e) => quoteAmountCalcu(index, "qty", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-28"><input type="Number" className='w-28 p-2 rounded-md text-black' name="rate" value={data.rate} onChange={(e) => quoteAmountCalcu(index, "rate", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-28"><input type="Number" className='w-28 p-2 rounded-md text-black' name="rate" value={data.tax} onChange={(e) => quoteAmountCalcu(index, "rate", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-28"><input type="Number"  className='w-28 p-2 rounded-md text-black' name="rate" value={data.rate} onChange={(e) => quoteAmountCalcu(index, "rate", e.target.value)} /></td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.amount || (formatNumberToInternational(((data.rate * (data.tax/100)) + data.rate)* data.qty)))}.00</td>
                    </tr>
                  ))}

                {invoiceType === "Quick" &&
                  productRows && productRows.map((data, index) => (
                    <tr key={index} className={
                      selectedRowId === data.id
                        ? "bg-indigo-800 text-white"
                        : "text-black"
                    }
                      onClick={() => handleRowClick(data.id)}>

                      <td className="px-2 py-2 border-2 border-gray-400 w-10">{index + 1}</td>
                      <th scope="row" className="px-2 py-2 w-44 font-medium whitespace-nowrap dark:text-white border-2 border-gray-400" onDoubleClick={() => showProductModal(index)}>{data.ProductCode}</th>

                      <td className="w-96 px-2 py-2 border-2 border-gray-400"
                       onDoubleClick={() => showProductModal(index)}>{data.ProductName}</td>

                      <td className="w-52 px-2 py-2 border-2 border-gray-400">{data.Specs}</td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.ProductUnit}</td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16"><input type="Number" className='w-16 p-2 rounded-md text-black' name="qty" onChange={(e) => amountCalcu(index, "qty", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-28"><input type="Number" className='w-28 p-2 rounded-md text-black' name="rate" value={data.rate} onChange={(e) => amountCalcu(index, "rate", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-28"><input type="Number" className='w-28 p-2 rounded-md text-black' name="tax" value={data.tax} onChange={(e) => amountCalcu(index, "tax", e.target.value)} /></td>

                      <td className="px-2 py-2 border-2 border-gray-400 w-16">{data.taxRate}</td>

                      <td className="w-32 px-2 py-2 border-2 border-gray-400">{formatNumberToInternational(data.amount)}.00</td>

                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="flex justify-end mt-4">
            <h3 className="text-xl font-semibold">Grand Total: {formatNumberToInternational(grandTotal)}.00</h3>
          </div>

          <div className='termCond'>
            <h1 className="flex text-2xl gap-2 cursor-pointer"> <span>Terms & Condition </span><MdAddCircle onClick={showTermCondModal} /></h1>
            <ul style={{ listStyle: "none" }}>
              {termCond && termCond.map((data, index) => (
                <li>{++index} - {data.description}</li>))}
            </ul>
          </div>

          <button className='SubButton' disabled={!submitButton}>{submitButton ? "Submit" : <>Please wait  <Spin className='text-white font-bold ml-2' indicator={<LoadingOutlined spin />} /> </>

          }</button>
        </form>
      </div>

      <QuotationModal
        isQuoteModalOpen={isQuoteModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectQuote={selectQuote}
      />
      <ClientModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectClient={selectClient}
      />

      <ProductModal
        isProductModalOpen={isProductModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        productSelect={productSelect}
      />

      <TermsCondModal
        isTermCondModalOpen={isTermCondModalOpen}
        accountName={"Invoices"}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectTermCond={selectTermCond}
      />

    </div>
  )
}
