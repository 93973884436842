import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { request } from '../../util/fetchAPI';
import { useNavigate } from "react-router-dom"
import { MdAddCircle } from "react-icons/md";


export default function TermsCondModal({ isTermCondModalOpen, handleOk, handleCancel, selectTermCond, accountName }) {
    const [TermCond, SetTermCond] = useState([]);
    const navigate = useNavigate()

    const fetchClient = async () => {
        try {
            const data = await request(`/TermCond/getTermCond/${accountName}`, "GET");
            SetTermCond(data);
        } catch (error) {
            console.log(error);
        }
    };



    useEffect(() => {
        fetchClient();
    }, []);

    return (
        <Modal
            width={"100%"}
            title="Select Client"
            open={isTermCondModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
        >

            <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                    <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">

                        <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                            <button type="button" className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800" onClick={() => navigate("/Settings")}>
                                <MdAddCircle className='mr-2 text-xl' />
                                Add New
                            </button>

                        </div>

                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-screen text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-4 py-3">select</th>
                                    <th scope="col" className="px-4 py-3">#</th>
                                    <th scope="col" className="px-4 py-3">Description</th>
                                    <th scope="col" className="px-4 py-3">Account Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TermCond.map((data, index) => (
                                    <tr key={data._id}
                                        className="hover:bg-indigo-600 hover:text-white cursor-pointer"
                                    >
                                        <td className="px-4 py-3">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => selectTermCond(e.target.checked, data)}
                                            />
                                        </td>
                                        <td className="px-4 py-3">{index + 1}</td>
                                        <td className="px-4 py-3">{data.description}</td>
                                        <td className="px-4 py-3">{data.accountName}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
